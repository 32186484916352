import { UserState } from 'store/types/UserActionTypes';
import AuthService from 'api/service/auth/AuthService';
import { ApiResponse, ApiRoutes } from 'api/Api';
import { PartnerSiteRegisterReq, PartnerSiteRegisterRes } from './PartnerSiteTypes';
import { PartnerSiteEditReq, PartnerSiteEditRes } from './PartnerSiteTypes';
import { PartnerSiteDetailReq, PartnerSiteDetailRes } from './PartnerSiteTypes';
import { PartnerSiteListReq, PartnerSiteListRes } from './PartnerSiteTypes';
import { PartnerSiteDeleteReq } from './PartnerSiteTypes';
import axios from 'axios';

export default function PartnerSiteService() {

  const authService = AuthService();

  const user: UserState = authService.getCurrentUser()!;

  // パートナーサイト登録
  async function partnerSiteRegister(parm: PartnerSiteRegisterReq): Promise<PartnerSiteRegisterRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.PARTNER_SITE_REGISTER, null, { params: parm });
      if (response && response?.params) {
        return response.params as PartnerSiteRegisterRes;
      }
    } catch(error) {
      console.error(error);
    }
    return {} as PartnerSiteRegisterRes;
  }

  // パートナーサイト編集
  async function partnerSiteEdit(parm: PartnerSiteEditReq): Promise<PartnerSiteEditRes> {
    try {
      parm = { ...parm, partnerId: user?.partnerId as number };
      const response: ApiResponse = await axios.post(ApiRoutes.PARTNER_SITE_EDIT, null, { params: parm });
      if (response && response?.params) {
        return response.params as PartnerSiteEditRes;
      }
    } catch(error) {
      console.error(error);
    }
    return {} as PartnerSiteEditRes;
  }

  // パートナーサイト詳細
  async function partnerSiteDetail(parm: PartnerSiteDetailReq): Promise<PartnerSiteDetailRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.PARTNER_SITE_DETAIL, null, { params: parm });
      if (response && response?.params) {
        return response.params as PartnerSiteDetailRes;
      }
    } catch(error) {
      console.error(error);
    }
    return {} as PartnerSiteDetailRes;
  }

  // パートナーサイト一覧
  async function partnerSiteList(parm?: PartnerSiteListReq): Promise<PartnerSiteListRes> {
    try {
      parm = { ...parm, partnerId: user?.partnerId as number };
      const response: ApiResponse = await axios.post(ApiRoutes.PARTNER_SITE_LIST, null, { params: parm });
      if (response && response?.params) {
        return response.params as PartnerSiteListRes;
      }
    } catch(error) {
      console.error(error);
    }
    return {} as PartnerSiteListRes;
  }

  // パートナーサイト削除
  async function partnerSiteDelete(parm: PartnerSiteDeleteReq): Promise<Array<string>> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.PARTNER_SITE_DELETE, null, { params: parm });
      if (response && response?.params) {
        return response.params as Array<string>;
      }
    } catch(error) {
      console.error(error);
    }
    return {} as Array<string>;
  }

  return {
    partnerSiteRegister,
    partnerSiteEdit,
    partnerSiteDetail,
    partnerSiteList,
    partnerSiteDelete
  }
}