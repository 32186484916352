import Backdrop from './Backdrop';
import './Loading.scss';

/**
 * ローディングコンポーネント
 *
 * @param {boolean} dark - ダークモードを有効にするかどうかのフラグ（オプション）
 * @param {boolean} show - ローディングコンポーネントを表示するかどうかのフラグ（オプション）
 */
export default function Loading({ dark, show }: { dark?: boolean, show?: boolean }) {
  return(
    <>
      {dark && <Backdrop open={dark}/>}
      <div className={show ? dark ? 'loading' : 'loading darkbar' : 'loading hide'}/>
    </>
  )
}