import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserState } from 'store/types/UserActionTypes';
import AuthService from 'api/service/auth/AuthService';
import PartnerService from 'api/service/partners/PartnerService';
import { PartnerEditReq, PartnerUsercheckReq } from 'api/service/partners/PartnerTypes';
import Empty from 'components/empty/Empty';
import { useDispatch } from 'react-redux';
import { loading, unloading } from 'store/actions/loadingActions';
import { showCenterPopup, showCenterLinkPopup, showCenterConfirmPopup } from 'store/actions/popupActions';
import './Partner.scss';

import LockIcon from '@mui/icons-material/Lock';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export interface LoginInfo {
  loginId: string | undefined;
  password: string | undefined;
  newPassword: string | undefined;
  newPasswordRe: string | undefined;
  required: Array<keyof LoginInfo>;
}

export interface AccountInfo {
  bankName: string | undefined;
  branchName: string | undefined;
  branchNameKana: string | undefined;
  branchCode: string | undefined;
  accountType: string | undefined;
  accountNumber: string | undefined;
  accountHolderKana: string | undefined;
  required: Array<keyof AccountInfo>;
}

export interface PartnerInfo {
  personId: number;
  nameAliasType: 0 | 1;
  email: string | undefined;
  companyName: string | undefined;
  companyNameKana: string | undefined;
  // registrationNumber: string | undefined;
  manager: string | undefined;
  tel: string | undefined;
  fax: string | undefined;
  postalCode: string | undefined;
  prefecture: string | undefined;
  address1: string | undefined;
  address2: string | undefined;
  required: Array<keyof PartnerInfo>;
}

export const BankAccountType: {[key: number]: string} = { 1: '普通', 2: '当座' }

export const Prefectures: {[key: number]: string} = {
  1: '北海道',
  2: '青森県',
  3: '岩手県',
  4: '宮城県',
  5: '秋田県',
  6: '山形県',
  7: '福島県',
  8: '茨城県',
  9: '栃木県',
  10: '群馬県',
  11: '埼玉県',
  12: '千葉県',
  13: '東京都',
  14: '神奈川県',
  15: '新潟県',
  16: '富山県',
  17: '石川県',
  18: '福井県',
  19: '山梨県',
  20: '長野県',
  21: '岐阜県',
  22: '静岡県',
  23: '愛知県',
  24: '三重県',
  25: '滋賀県',
  26: '京都府',
  27: '大阪府',
  28: '兵庫県',
  29: '奈良県',
  30: '和歌山県',
  31: '鳥取県',
  32: '島根県',
  33: '岡山県',
  34: '広島県',
  35: '山口県',
  36: '徳島県',
  37: '香川県',
  38: '愛媛県',
  39: '高知県',
  40: '福岡県',
  41: '佐賀県',
  42: '長崎県',
  43: '熊本県',
  44: '大分県',
  45: '宮崎県',
  46: '鹿児島県',
  47: '沖縄県',
}

export default function PartnerEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('t');

  const authService = AuthService();
  const partnerService = PartnerService();

  const infoMsg: string = 'チェック項目は必須入力';

  const [pwCheck, setPwCheck] = useState<boolean>(false);
  const [sessionCheck, setSessionCheck] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');
  const [user, setUser] = useState<UserState | undefined>(undefined);
  const [loginInfo, setLoginInfo] = useState<LoginInfo>({
    loginId: '', password: '', newPassword: '', newPasswordRe: '',
    required: ['loginId', 'password', 'newPassword', 'newPasswordRe']
  });
  const [accountInfo, setAccountInfo] = useState<AccountInfo>({
    bankName: '', branchName: '', branchNameKana: '', branchCode: '', accountType: '', accountNumber: '', accountHolderKana: '',
    required: []
  });
  const [partnerInfo, setPartnerInfo] = useState<PartnerInfo>({
    personId: 0, nameAliasType: 0, email: '', companyName: '', companyNameKana: '', manager: '', tel: '', fax: '', postalCode: '', prefecture: '', address1: '', address2: '',
    required: ['email', 'companyName']
  });
  
  useEffect(() => {
    const currentUser = authService.getCurrentUser();
    if (currentUser) {
      setUser(currentUser);
    } else {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (sessionCheck) {
      intervalId = setInterval(() => {
        const popup = sessionStorage.getItem('popup');
        if (popup) {
          const popupState = JSON.parse(popup);
          if (!popupState.opened) {
            setSessionCheck(false);
          }
          if (popupState.ok) {
            setSessionCheck(false);
            save();
          }
        }
      }, 500);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [sessionCheck]);

  useEffect(() => {
    if (user && pwCheck) {
      getPartnerInfo();
    }
  }, [user, pwCheck]);

  function load() {
    dispatch(loading(true));
  }
  
  function unload() {
    dispatch(unloading());
  }

  async function getPartnerInfo() {
    load();
    await partnerService.partnerDetail().then((res) => {
      // ログイン情報セット
      if (type === 'login') {
        setLoginInfo({ ...loginInfo, 
          loginId: res.loginId
        });
      }
      // 口座情報セット
      else if (type === 'account') {
        setAccountInfo({ ...accountInfo, 
          bankName: res.bankName,
          branchName: res.bankBranchName,
          branchNameKana: res.bankBranchNameKana,
          branchCode: res.bankBranchCode,
          accountType: BankAccountType[res.bankAccountType],
          accountNumber: res.bankAccountNumber,
          accountHolderKana: res.bankAccountNameKana,
        });
      }
      // 登録者情報セット
      else if (type === 'partner') {
        setPartnerInfo({ ...partnerInfo, 
          email: res.mailAddress,
          companyName: res.name,
          companyNameKana: res.nameKana,
          manager: res.personName,
          tel: res.tel,
          fax: res.fax,
          postalCode: res.zipCode,
          prefecture: Prefectures[res.prefectures],
          address1: res.address1,
          address2: res.address2
        });
      }
      unload();
    })
  }
  
  async function pwCheckSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setErrMsg('');
    const parm: PartnerUsercheckReq = {
      loginId: '',
      password: loginInfo.password as string
    }
    await partnerService.partnerUsercheck(parm).then((res) => {
      if (res.result) {
        setPwCheck(true);
      } else {
        dispatch(showCenterPopup('アクセス拒否', 'パスワードが一致しません'));
      }
    })
  }

  function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setErrMsg('');
    dispatch(showCenterConfirmPopup('内容確認', '変更内容を保存しますか？'));
    setSessionCheck(true);
  }

  async function save() {
    let isSave: boolean = false;
    if (type === 'login') {
      const check: boolean = (loginInfo.required.every(field => !!loginInfo[field]) && loginInfo.newPassword === loginInfo.newPasswordRe);
      if (check) {
        isSave = true;
      }
    } else if (type === 'account') {
      const check: boolean = accountInfo.required.every(field => !!accountInfo[field]);
      if (check) {
        isSave = true;
      }
    } else if (type === 'partner') {
      const check: boolean = partnerInfo.required.every(field => !!partnerInfo[field]);
      if (check) {
        isSave = true;
      }
    }

    if (user && isSave) {
      load();
      // 必須項目
      let parm: PartnerEditReq = {
        partnerId: 0,
        personId: partnerInfo.personId,
        loginId: user.userId as string,
        password: loginInfo.password as string,
        mailAddress: user.mail as string,
        name: partnerInfo.companyName as string,
        nameAliasType: partnerInfo.nameAliasType
      }
      // 各保存項目
      if (type === 'login') {
        parm = { ...parm, 
          password: loginInfo.newPassword as string
        }
      } else if (type === 'account') {
        parm = { ...parm, 
          bankName: accountInfo.bankName,
          bankBranchName: accountInfo.branchName,
          bankBranchNameKana: accountInfo.branchNameKana,
          bankBranchCode: Number(accountInfo.branchCode),
          bankAccountType: Number(accountInfo.accountType) as 1 | 2 | undefined,
          bankAccountNumber: Number(accountInfo.accountNumber),
          bankAccountNameKana: accountInfo.accountHolderKana,
        }
      } else if (type === 'partner') {
        parm = { ...parm,
          name: partnerInfo.companyName as string,
          nameKana: partnerInfo.companyNameKana,
          person: partnerInfo.manager as string,
          tel: Number(partnerInfo.tel),
          fax: Number(partnerInfo.fax),
          prefectures: Number(partnerInfo.prefecture),
          address1: partnerInfo.address1,
          address2: partnerInfo.address2
        }
      }
      await partnerService.partnerEdit(parm).then((res) => {
        if (user.partnerId === Number(res.partnerId)) {
          dispatch(showCenterLinkPopup('完了', '変更内容が保存されました' , '/partner'));
        }
      })
      unload();
    } else {
      setErrMsg('入力値をもう一度ご確認ください')
    }
  }

  function EditTitle({ title, pwFlg = true }: { title: string, pwFlg?: boolean }) {
    return(
      <>
        <label>
          {pwFlg && (
            type === 'login' ?
            <LockIcon className='icon'/>
            : type === 'account' ?
            <AccountBalanceIcon className='icon'/>
            : type === 'partner' &&
            <PersonIcon className='icon'/>
          )}
          <span className='title'>{title}</span>
        </label>
        {pwFlg && <span className={errMsg ? 'errmsg' : 'info'}>{errMsg && <WarningRoundedIcon className='err-icon'/>}{errMsg ? errMsg : infoMsg}</span>}
      </>
    )
  }

  function EditLabel({ title, field }: { title: string, field: keyof LoginInfo | keyof AccountInfo | keyof PartnerInfo | 'check' }) {
    const targetInfo: Array<any> = type === 'login' ? loginInfo.required : type === 'account' ? accountInfo.required : type === 'partner' ? partnerInfo.required : [];
    const required: boolean = targetInfo.includes(field);
    return(
      <dt className='title'>
        {title}
        {required && <CheckRoundedIcon className='icon'/>}
      </dt>
    )
  }

  function EditButtons({ pwFlg }: { pwFlg?: boolean}) {
    return(
      <div className='sub-area'>
        <button className='back-btn' type='button' onClick={() => navigate(-1)}>戻る</button>
        <button className='save-btn' type='submit'>{pwFlg ? '確認' : '変更内容を保存'}</button>
      </div>
    )
  }

  if (pwCheck) {
    if (type === 'login') {
      return (
        <section className='partner-edit'>
          <form onSubmit={submit}>
            <div className='edit-area'>
              <EditTitle title='ログイン情報変更'/>
              <dl>
                <EditLabel title='ログインID' field='loginId'/>
                <dd><input type='text' autoComplete='login-id' value={loginInfo.loginId} onChange={(e) => {}} placeholder={'未設定'} disabled/></dd>
              </dl>
              <dl>
                <EditLabel title='現在のパスワード' field='password'/>
                <dd><input type='password' autoComplete='current-password' value={loginInfo.password} placeholder={'未設定'} disabled/></dd>
              </dl>
              <dl>
                <EditLabel title='新しいパスワード' field='newPassword'/>
                <dd><input type='password' autoComplete='new-password' value={loginInfo.newPassword} onChange={(e) => setLoginInfo({...loginInfo, newPassword: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
              <dl>
                <EditLabel title='新しいパスワード(確認用)' field='newPasswordRe'/>
                <dd><input type='password' autoComplete='new-password' value={loginInfo.newPasswordRe} onChange={(e) => setLoginInfo({...loginInfo, newPasswordRe: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
            </div>
            <EditButtons/>
          </form>
        </section>
      )
    } else if (type === 'account') {
      return (
        <section className='partner-edit'>
          <form onSubmit={(e) => submit(e)}>
            <div className='edit-area'>
              <EditTitle title='口座情報変更'/>
              <dl>
                <EditLabel title='振込先銀行名' field='bankName'/>
                <dd><input type='text' value={accountInfo.bankName} onChange={(e) => setAccountInfo({...accountInfo, bankName: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
              <dl>
                <EditLabel title='支店名' field='branchName'/>
                <dd><input type='text' value={accountInfo.branchName} onChange={(e) => setAccountInfo({...accountInfo, branchName: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
              <dl>
                <EditLabel title='支店名(カナ)' field='branchNameKana'/>
                <dd><input type='text' value={accountInfo.branchNameKana} onChange={(e) => setAccountInfo({...accountInfo, branchNameKana: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
              <dl>
                <EditLabel title='支店名コード' field='branchCode'/>
                <dd><input type='number' value={accountInfo.branchCode} onChange={(e) => setAccountInfo({...accountInfo, branchCode: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
              <dl>
                <EditLabel title='口座種別' field='accountType'/>
                <dd>
                  <div className='radio'>
                    {Object.keys(BankAccountType).map((key: string) => (
                      <div className='items' key={key}>
                        <input type='radio' value={key} checked={accountInfo.accountType === key} 
                          onChange={(e) => setAccountInfo({...accountInfo, accountType: e.target.value})}/>
                        <label>{BankAccountType[Number(key)]}</label>
                      </div>
                    ))}
                  </div>
                </dd>
              </dl>
              <dl>
                <EditLabel title='口座番号' field='accountNumber'/>
                <dd><input type='number' value={accountInfo.accountNumber} onChange={(e) => setAccountInfo({...accountInfo, accountNumber: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
              <dl>
                <EditLabel title='口座名義(カナ)' field='accountHolderKana'/>
                <dd><input type='text' value={accountInfo.accountHolderKana} onChange={(e) => setAccountInfo({...accountInfo, accountHolderKana: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
            </div>
            <EditButtons/>
          </form>
        </section>
      )
    } else if (type === 'partner') {
      return (
        <section className='partner-edit'>
          <form onSubmit={(e) => submit(e)}>
            <div className='edit-area'>
              <EditTitle title='登録者情報変更'/>
              <dl>
                <EditLabel title='メールアドレス' field='email'/>
                <dd><input type='text' value={partnerInfo.email} onChange={(e) => {}} placeholder={'未設定'} disabled/></dd>
              </dl>
              <dl>
                <EditLabel title='企業名/個人名' field='companyName'/>
                <dd><input type='text' value={partnerInfo.companyName} onChange={(e) => setPartnerInfo({...partnerInfo, companyName: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
              <dl>
                <EditLabel title='企業名/個人名(カナ)' field='companyNameKana'/>
                <dd><input type='text' value={partnerInfo.companyNameKana} onChange={(e) => setPartnerInfo({...partnerInfo, companyNameKana: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
              {/* <dl>
                <EditLabel title='連絡請求書発行事業者の登録番号' field='registrationNumber'/>
                <dd><input type='text' value={partnerInfo.registrationNumber} onChange={(e) => setPartnerInfo({...partnerInfo, registrationNumber: e.target.value})} placeholder={'未設定'}/></dd>
              </dl> */}
              <dl>
                <EditLabel title='担当者名' field='manager'/>
                <dd><input type='text' value={partnerInfo.manager} onChange={(e) => setPartnerInfo({...partnerInfo, manager: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
              <dl>
                <EditLabel title='TEL' field='tel'/>
                <dd><input type='number' value={partnerInfo.tel} onChange={(e) => setPartnerInfo({...partnerInfo, tel: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
              <dl>
                <EditLabel title='FAX' field='fax'/>
                <dd><input type='number' value={partnerInfo.fax} onChange={(e) => setPartnerInfo({...partnerInfo, fax: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
              <dl>
                <EditLabel title='郵便番号' field='postalCode'/>
                <dd><input type='number' value={partnerInfo.postalCode} onChange={(e) => setPartnerInfo({...partnerInfo, postalCode: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
              <dl>
                <EditLabel title='都道府県' field='prefecture'/>
                <dd>
                  <select 
                    name='pref'
                    value={(Object.keys(Prefectures)).find(key => Prefectures[Number(key)] === partnerInfo.prefecture) || partnerInfo.prefecture} 
                    onChange={(e) => setPartnerInfo({...partnerInfo, prefecture: e.target.value})}>
                    <option value='' disabled>都道府県選択</option>
                    {Object.keys(Prefectures).map((key: string) => (
                      <option key={key} value={key}>
                        {Prefectures[Number(key)]}
                      </option>
                    ))}
                  </select>
                </dd>
              </dl>
              <dl>
                <EditLabel title='住所1(市区町村)' field='address1'/>
                <dd><input type='text' value={partnerInfo.address1} onChange={(e) => setPartnerInfo({...partnerInfo, address1: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
              <dl>
                <EditLabel title='住所2(番地建物)' field='address2'/>
                <dd><input type='text' value={partnerInfo.address2} onChange={(e) => setPartnerInfo({...partnerInfo, address2: e.target.value})} placeholder={'未設定'}/></dd>
              </dl>
            </div>
            <EditButtons/>
          </form>
        </section>
      )
    } else {
      return(
        <Empty/>
      )
    }
  } else {
    return (
      <section className='partner-edit'>
        <form onSubmit={(e) => pwCheckSubmit(e)}>
          <div className='edit-area'>
            <EditTitle title='パスワード確認' pwFlg={false}/>
            <dl>
              <EditLabel title='パスワード' field='check'/>
              <dd><input type='password' value={loginInfo.password} onChange={(e) => setLoginInfo({...loginInfo, password: e.target.value})} placeholder={'パスワードを入力'}/></dd>
            </dl>
          </div>
          <EditButtons pwFlg={true}/>
        </form>
      </section>
    )
  }
}