import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { loading, unloading } from 'store/actions/loadingActions';
import { showTopPopup } from 'store/actions/popupActions';
import FilterModal, { ModalItem } from 'components/modal/FilterModal';
import MainTable, { Column } from 'components/table/MainTable';
import TagService from 'api/service/alliance/TagService';
import { TagClickListReq } from 'api/service/alliance/TagTypes';
import AdService from 'api/service/ad/AdService';
import PartnerSiteService from 'api/service/partners/PartnerSiteService';
import { AdAllianceListReq } from 'api/service/ad/AdTypes';
import './Status.scss';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface StatusData {
  siteName: string;
  adName: string;
  unitPrice: number;
  code: React.ReactElement<any, any>;
  lpLink: React.ReactElement<any, any>;
}

export default function ReportStatus() {
  const isSp = useMediaQuery({ maxWidth: 767 });
  const dispatch = useDispatch();
  const location = useLocation();
  
  const tagService = TagService();
  const adService = AdService();
  const partnerSiteService = PartnerSiteService();

  const perPageList: Array<number> = [30, 60, 120];
  const [totalNum, setTotalNum] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(perPageList[0]);
  
  const [modalItem, setModalItem] = useState<Array<ModalItem>>([
    { title: 'パートナーサイト', datas: { type: 'select', value: [], element: [] } },
    { title: '広告', datas: { type: 'select', value: [], element: [] } }
  ]);
  const siteItemIdx: number = 0;
  const adItemIdx: number = 1;
  const columns: Array<Column> = [
    { id: 'siteName', label: 'サイト名', hide: isSp, minWidth: 100 },
    { id: 'adName', label: '広告名', minWidth: 200 },
    { id: 'unitPrice', label: '単価', minWidth: 100, align: 'right', format: (value: number) => value.toLocaleString('en-US') },
    { id: 'code', label: '発行コード', minWidth: 150 },
    { id: 'lpLink', label: 'LP', align: 'center', hide: isSp, width: 40 },
  ];
  const [rows, setRows] = useState<Array<StatusData>>([]);
  const [initialFlg, setInitialFlg] = useState<boolean>(true);
  
  useEffect(() => {
    getMeodalInfo();
  }, []);

  useEffect(() => {
    const siteId: number = location.state?.siteId;
    if (siteId && initialFlg && modalItem[siteItemIdx].datas.type === 'select' && modalItem[siteItemIdx].datas.element) {
      const siteItem = modalItem[siteItemIdx].datas.element;
      if (!siteItem.length) {
        return;
      }
      const filteredSiteItem = siteItem.filter(item => item.value == siteId);
      const value: Array<{ label: string, value: number }> = [{ label: filteredSiteItem[0]?.label, value: Number(siteId) }]
      modalItem[siteItemIdx].datas.value = value;
    }
    getStatusTable();
  }, [location, page, perPage, modalItem]);

  function load() {
    dispatch(loading(true));
  }
  
  function unload() {
    dispatch(unloading());
  }

  async function getMeodalInfo() {
    load();
    const filterSiteList: Array<{label: string, value: number}> = [];
    const filterAdList: Array<{label: string, value: number}> = [];
  
    await partnerSiteService.partnerSiteList().then((res) => {
      res.sites?.forEach((site) => {
        filterSiteList.push({ label: site.siteName, value: Number(site.siteId) })
        const parm: AdAllianceListReq = {
          partnerSiteId: site.siteId,
          row: 120
        }
        adService.adAllianceList(parm).then((res) => {
          res.contents?.forEach((content) => {
            const contentIdx = filterAdList.findIndex(item => item.value === Number(content.contentId));
            if (contentIdx === -1) {
              filterAdList.push({ label: content.contentName, value: Number(content.contentId) });
            }
          })
        })
      })
    })
  
    setModalItem((prev) => {
      const modalItems = [...prev];
      const siteItem = modalItems[siteItemIdx];
      const adItem = modalItems[adItemIdx];
      if (siteItem.datas.type === 'select') {
        modalItems[siteItemIdx] = { ...siteItem,
          datas: { ...siteItem.datas,
            element: filterSiteList
          }
        };
      }
      if (adItem.datas.type === 'select') {
        modalItems[adItemIdx] = { ...adItem,
          datas: { ...adItem.datas,
            element: filterAdList
          }
        };
      }
      return modalItems;
    });
    unload();
  }

  // 単価、発行コード、LP修正要
  async function getStatusTable() {
    load();
    const seletedSite = modalItem[siteItemIdx].datas.value as Array<{ label: string, value: number }>;
    const seletedAd = modalItem[adItemIdx].datas.value as Array<{ label: string, value: number }>;
    const parm: TagClickListReq = {
      siteId: seletedSite[0]?.value,
      contentId: seletedAd[0]?.value,
      row: perPage,
      page: page,
      aliveStatus: 0
    }
    await tagService.tagClickList(parm).then((res) => {
      const tableDataList: Array<StatusData> = [];
      setTotalNum(res.totalNum);
      res.clickUrl.forEach((data) =>{
        tableDataList.push(createData(
          data.siteName,
          data.contentName,
          0,  // TODO: API修正依頼済み-単価
          '', // TODO: API修正依頼済み-発行コード
          ''  // TODO: API修正依頼済み-LPのURL
        ))
      })
      setRows(tableDataList);
    })
    setInitialFlg(false);
    unload();
  }

  function codeCopy(url: string) {
    if(navigator.clipboard){
      navigator.clipboard.writeText(url);
      dispatch(showTopPopup('コードがコピーされました'));
    }
  }

  function openLp(url: string) {
    window.open(url, "_blank");
  }

  function createData(siteName: string, adName: string, unitPrice: number, codeUrl: string, lpUrl: string): StatusData {
    const code: React.ReactElement<any, any> = codeUrl ?
      <div className='code'>
        <button className='additional-btn code' onClick={() => codeCopy(codeUrl)}>
          <ContentCopyIcon className='icon'/>
        </button>
        <span className='url'>{codeUrl}</span>
      </div>
      :
      <></>
    const lpLink: React.ReactElement<any, any> = lpUrl ?
      <button className='additional-btn lp' onClick={() => openLp(lpUrl)}>
        <OpenInNewIcon className='icon'/>
      </button>
      :
      <></>
    return { siteName, adName, unitPrice, code, lpLink };
  }

  return(
    <section className='status'>
      <div className='top'>
        <FilterModal items={modalItem} setItems={setModalItem}/>
      </div>
      <div className='bottom'>
        <MainTable totalNum={totalNum} columns={columns} rows={rows}
          page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} perPageList={perPageList}/>
      </div>
    </section>
  )
}