import { UserState, UserAction, UserActionTypes } from "../types/UserActionTypes";

const initialState: UserState = {
  partnerId: undefined,
  userId: undefined,
  userName: undefined,
  mail: undefined,
}

export default function userReducer(
  state = initialState,
  action: UserAction
): UserState {
  switch (action.type) {
    case UserActionTypes.USER_LOGIN:
      return { ...state, partnerId: action.state.partnerId, userId: action.state.userId, userName: action.state.userName, mail: action.state.mail };
    case UserActionTypes.USER_LOGOUT:
      return { ...state, partnerId: undefined, userId: undefined, userName: undefined, mail: undefined };
    default:
      return state;
  }
}