import { UserState } from 'store/types/UserActionTypes';
import AuthService from 'api/service/auth/AuthService';
import { ApiResponse, ApiRoutes } from 'api/Api';
import { TagClickListReq, TagClickListRes } from 'api/service/alliance/TagTypes';
import { TagMeasureListReq, TagMeasureListRes } from 'api/service/alliance/TagTypes';
import { TagGroupMeasureListReq, TagGroupMeasureListRes } from 'api/service/alliance/TagTypes';
import { TagResultListReq, TagResultListRes } from 'api/service/alliance/TagTypes';
import { TagGroupResultListReq, TagGroupResultListRes } from 'api/service/alliance/TagTypes'
import { TagPriceDetailReq, TagPriceDetailRes } from 'api/service/alliance/TagTypes'
import { TagPriceEditReq, TagPriceEditRes } from 'api/service/alliance/TagTypes'
import { TagPriceDeleteReq, TagPriceDeleteRes } from 'api/service/alliance/TagTypes'
import axios from 'axios';

export default function TagService() {

  const authService = AuthService();

  const user: UserState = authService.getCurrentUser()!;

  // クリックURL一覧
  async function tagClickList(parm?: TagClickListReq): Promise<TagClickListRes> {
    try {
      parm = { ...parm, partnerId: user?.partnerId as number } as TagClickListReq;
      const response: ApiResponse = await axios.post(ApiRoutes.TAG_CLICK_LIST, null, { params: parm });
      if (response && response?.params) {
        return response.params as TagClickListRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as TagClickListRes;
  }

  // 計測タグ一覧
  async function tagMeasureList(parm?: TagMeasureListReq): Promise<TagMeasureListRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.TAG_MEASURE_LIST, null, { params: parm });
      if (response && response?.params) {
        return response.params as TagMeasureListRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as TagMeasureListRes;
  }

  // グループ計測タグ一覧
  async function tagGroupMeasureList(parm?: TagGroupMeasureListReq): Promise<TagGroupMeasureListRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.TAG_GROUP_MEASURE_LIST, null, { params: parm });
      if (response && response?.params) {
        return response.params as TagGroupMeasureListRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as TagGroupMeasureListRes;
  }

  // 成果タグ一覧
  async function tagResultList(parm?: TagResultListReq): Promise<TagResultListRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.TAG_RESULT_LIST, null, { params: parm });
      if (response && response?.params) {
        return response.params as TagResultListRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as TagResultListRes;
  }

  // グループ成果タグ一覧
  async function tagGroupResultList(parm?: TagGroupResultListReq): Promise<TagGroupResultListRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.TAG_GROUP_RESULT_LIST, null, { params: parm });
      if (response && response?.params) {
        return response.params as TagGroupResultListRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as TagGroupResultListRes;
  }

  // 特別単価詳細閲覧
  async function tagPriceDetail(parm: TagPriceDetailReq): Promise<TagPriceDetailRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.TAG_PRICE_DETAIL, null, { params: parm });
      if (response && response?.params) {
        return response.params as TagPriceDetailRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as TagPriceDetailRes;
  }

  // 特別単価編集
  async function tagPriceEdit(parm: TagPriceEditReq): Promise<TagPriceEditRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.TAG_PRICE_EDIT, null, { params: parm });
      if (response && response?.params) {
        return response.params as TagPriceEditRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as TagPriceEditRes;
  }

  // 特別単価削除
  async function tagPriceDelete(parm: TagPriceDeleteReq): Promise<TagPriceDeleteRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.TAG_PRICE_DELETE, null, { params: parm });
      if (response && response?.params) {
        return response.params as TagPriceDeleteRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as TagPriceDeleteRes;
  }

  return {
    tagClickList,
    tagMeasureList,
    tagGroupMeasureList,
    tagResultList,
    tagGroupResultList,
    tagPriceDetail,
    tagPriceEdit,
    tagPriceDelete
  }
}