import { useNavigate } from "react-router-dom";
import './Empty.scss';

import AnnouncementTwoToneIcon from '@mui/icons-material/AnnouncementTwoTone';

/**
 * 空ページを表示させるコンポーネント
 */
export default function Empty() {
  const navigate = useNavigate();
  const emptyMessageEng: string = 'Not found Page or Data';
  const emptyMessage: string = 'ページ又は\nデータがありません';

  return(
    <section className="empty">
      <div className="box">
        <AnnouncementTwoToneIcon className='icon'/>
        <div className="message">
          <span className='eng'>{emptyMessageEng}</span>
          <span>{ emptyMessage }</span>
          <button className="back-btn" onClick={() => navigate(-1)}>戻る</button>
        </div>
      </div>
    </section>
  )
}