import { useEffect, useState, ReactElement } from 'react';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import AuthService from 'api/service/auth/AuthService';
import './SideNav.scss';

import MenuItem from '@mui/material/MenuItem';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

export interface MenuItem {
  category: string;
  categoryPath: string;
  categoryIcon: ReactElement<any, any>;
  items: {
    path: string,
    title: string
  }[];
}

/**
 * サイドナビゲーションコンポーネント
 *
 * @param {MenuItem[]} menuItem - メニューアイテムの配列
 * @param {string | undefined} currentPath - 現在のパス
 * @param {boolean} mini - ミニ表示モードの有無
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setMini - ミニ表示モードを設定するための関数
 * @param {boolean} open - サイドバーオープン状態
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setOpen - サイドバーオープン状態を設定するための関数
 */
export default function SideNav({ menuItem, currentPath, mini, setMini, open, setOpen }: { menuItem: MenuItem[], currentPath: string | undefined, mini: boolean, setMini: React.Dispatch<React.SetStateAction<boolean>>, open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>}) {
  const isSp = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();

  const authService = AuthService();

  const [activeCategory, setActiveCategory] = useState<string>(menuItem[0].categoryPath);

  useEffect(() => {
    if (currentPath) {
      setActiveCategory(currentPath.split('/')[0]);
    } else {
      setActiveCategory('');
    }
  }, [currentPath, mini, open]);

  async function logout() {
    await authService.logout().then(data => {
      window.location.reload();
    });
  }
  
  function menuClick(path: string) {
    if (path === activeCategory) {
      setActiveCategory('');
    } else {
      setActiveCategory(path)
    }
  }

  function link(path: string) {
    navigate(path);
    if (isSp) {
      setOpen(false);
    }
  }
  
  if (isSp) {
    return(
      <nav className={open ? 'side-nav-sp open' : 'side-nav-sp'} role='navigation'>
        <button className='logout-btn' onClick={() => logout()}>
          <span className='text'><PowerSettingsNewRoundedIcon className='icon'/></span>
        </button>
        <button className='menu-btn' onClick={() => setOpen(!open)}>
          <CloseRoundedIcon className='icon'/>
        </button>
        <ul>
          <li className={currentPath === '' ? 'active' : ''} onClick={() => link('/')} key={'home'}>
            {<HomeRoundedIcon/>}
            <span className='title'>{'ホーム'}</span>
          </li>
          {menuItem.map(menu => (
            // 子メニューあり
            menu.items.length > 0 ?
            <div key={menu.categoryPath}>
              <li className={activeCategory === menu.categoryPath ? 'active' : ''} onClick={() => menuClick(menu.categoryPath)}>
                {menu.categoryIcon}
                <span className='title'>{menu.category}</span>
                {<ArrowDropDownRoundedIcon className={activeCategory === menu.categoryPath ? 'icon open' : 'icon'}/>}
              </li>
              <div className={activeCategory === menu.categoryPath ? 'child-menu' : 'child-menu void'} key={menu.categoryPath + 'child'}>
                {menu.items.map(item => (
                  <li className={currentPath === item.path ? 'active' : ''} onClick={() => link(item.path)} key={item.path}>{item.title}</li>
                ))}
              </div>
            </div>
            :
            // 子メニューなし
            <li className={currentPath?.indexOf(menu.categoryPath) !== -1 ? 'active' : ''} onClick={() => link(menu.categoryPath)} key={menu.categoryPath}>
              {menu.categoryIcon}
              <span className='title'>{menu.category}</span>
            </li>
          ))}
        </ul>
      </nav>
    )
  } else {
    return(
      <nav className={mini ? 'side-nav mini' : 'side-nav'} role='navigation'>
        <ul onMouseEnter={() => {setMini(false); setOpen(true);}} onMouseLeave={() => {setMini(true); setOpen(false);}}>
          <li className={currentPath === '' ? 'active' : ''} onClick={() => link('/')} key={'home'}>
            {<HomeRoundedIcon/>}
            <span className='title'>{!mini && 'ホーム'}</span>
          </li>
          {menuItem.map(menu => (
            // 子メニューあり
            menu.items.length > 0 ?
            <div key={menu.categoryPath}>
              <li className={activeCategory === menu.categoryPath && mini ? 'active' : ''} onClick={() => menuClick(menu.categoryPath)}>
                {menu.categoryIcon}
                <span className='title'>{!mini && menu.category}</span>
                {!mini && <ArrowDropDownRoundedIcon className={activeCategory === menu.categoryPath ? 'icon open' : 'icon'}/>}
              </li>
              {!mini &&
                <div className={activeCategory === menu.categoryPath ? 'child-menu' : 'child-menu void'} key={menu.categoryPath + 'child'}>
                  {menu.items.map(item => (
                    <li className={currentPath === item.path ? 'active' : ''} onClick={() => link(item.path)} key={item.path}>{item.title}</li>
                  ))}
                </div>
              }
            </div>
            :
            // 子メニューなし
            <li className={currentPath?.indexOf(menu.categoryPath) !== -1 ? 'active' : ''} onClick={() => link(menu.categoryPath)} key={menu.categoryPath}>
              {menu.categoryIcon}
              <span className='title'>{!mini && menu.category}</span>
            </li>
          ))}
        </ul>
      </nav>
    )
  }
}