import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { RootState } from "store/reducers";
import { hidePopup } from "store/actions/popupActions";
import Backdrop from 'components/backdrop/Backdrop'
import './Popup.scss';

import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';

interface PopupProps {
  title?: string;
  contents: string;
  center: boolean;
  link?: string;
  confirm?: boolean;
  backdropClose?: boolean;
}

/**
 * ポップアップコンポーネント
 *
 * @param {string} title - ポップアップのタイトル（オプション）
 * @param {string} contents - ポップアップのコンテンツ
 * @param {string} link - リンクが提供された場合、ポップアップを閉じて指定されたリンクに移動（オプション）
 * @param {boolean} center - ポップアップを中央に表示させるフラグ
 * @param {boolean} confirm - 確認ポップアップを表示させるフラグ
 * @param {boolean} backdropClose - バックドロップをクリックして閉じる（デフォルト`true`）
 */
export default function Popup({ title = '', contents, link, center, confirm, backdropClose = true }: PopupProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const show = useSelector((state: RootState) => state.popup.isShow);
  const [errFlg, setErrFlg] = useState<boolean>(false);
  const [okFlg, setOkFlg] = useState<boolean>(false);
  const errKeyword: Array<string> = ['error', 'エラー', '権限', '以内', '一致しません', '不正'];

  // 確認ポップアップが開く時にOKフラグを初期化
  useEffect(() => {
    if (show && confirm) {
      setOkFlg(false);
    }
  }, [show, confirm]);

  useEffect(() => {
    sessionStorage.setItem('popup', JSON.stringify({ opened: show, ok: okFlg }));
  }, [show, okFlg]);

  useEffect(() => {
    const containsError: boolean = errKeyword.some(err => {
      const lowercaseContents = contents.toLowerCase();
      const lowercaseErr = err.toLowerCase();
      return lowercaseContents.includes(lowercaseErr);
    });
    setErrFlg(containsError);
  }, [contents]);

  function popupClose(ok: boolean) {
    dispatch(hidePopup());
    if (ok) {
      setOkFlg(true);
      if (link) {
        navigate(link, { state: { prev: location.pathname } });
      }
    } else {
      setOkFlg(false);
    }
  }

  return(
    <>
    <Backdrop open={center && show} onClick={() => backdropClose && popupClose(false)}/>
    {/* 上段ポップアップ */}
    <div className={'popup' + (show ? ' show' : ' hide') + (center ? ' none' : '')} onClick={() => popupClose(true)}>
      <div className='text'>
        { contents }
      </div>
    </div>
    {/* 中央ポップアップ */}
    <div className={'popup-center' + (show ? ' show' : ' hide') + (center ? '' : ' none')}>
      {errFlg ? 
        <BoltRoundedIcon className='icon error' sx={{ fontSize: 35 }}/>
        :
        confirm ?
          <QuestionMarkRoundedIcon className='icon ' sx={{ fontSize: 35 }}/>
          :
          <CheckRoundedIcon className='icon' sx={{ fontSize: 35 }}/>
      }
      <div className='popup-title'>
        { title }
      </div>
      <div className='popup-contents'>
        { contents }
      </div>
      <div className='popup-btn-area'>
        {confirm && <button className='popup-btn' onClick={() => popupClose(false)}>Cancle</button>}
        <button className='popup-btn' onClick={() => popupClose(true)}>OK</button>
      </div>
    </div>
    </>
  )
}