import { ApiResponse, ApiRoutes } from 'api/Api';
import { AdRegisterReq, AdRegisterRes } from 'api/service/ad/AdTypes';
import { AdEditReq, AdEditRes } from 'api/service/ad/AdTypes';
import { AdDetailReq, AdDetailRes } from 'api/service/ad/AdTypes';
import { AdListReq, AdListRes } from 'api/service/ad/AdTypes';
import { AdDeleteReq } from 'api/service/ad/AdTypes';
import { AdAllianceListReq, AdAllianceListRes } from 'api/service/ad/AdTypes';
import { AdDetailPartnerReq, AdDetailPartnerRes } from 'api/service/ad/AdTypes';
import axios from 'axios';

export default function ContentService() {

  // 広告登録
  async function adRegister(parm: AdRegisterReq): Promise<AdRegisterRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.AD_REGISTER, null, { params: parm });
      if (response && response?.params) {
        return response.params as AdRegisterRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as AdRegisterRes;
  }

  // 広告編集
  async function adEdit(parm: AdEditReq): Promise<AdEditRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.AD_EDIT, null, { params: parm });
      if (response && response?.params) {
        return response.params as AdEditRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as AdEditRes;
  }

  // 広告詳細
  async function adDetail(parm: AdDetailReq): Promise<AdDetailRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.AD_DETAIL, null, { params: parm });
      if (response && response?.params) {
        return response.params as AdDetailRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as AdDetailRes;
  }

  // 広告一覧
  async function adList(parm: AdListReq): Promise<AdListRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.AD_LIST, null, { params: parm });
      if (response && response?.params) {
        return response.params as AdListRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as AdListRes;
  }

  // 広告削除
  async function adDelete(parm: AdDeleteReq): Promise<Array<string>> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.AD_DELETE, null, { params: parm });
      if (response && response?.params) {
        return response.params as Array<string>;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as Array<string>;
  }

  // 広告一覧(パートナー用)
  async function adAllianceList(parm: AdAllianceListReq): Promise<AdAllianceListRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.AD_ALLIANCELIST, null, { params: parm });
      if (response && response?.params) {
        return response.params as AdAllianceListRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as AdAllianceListRes;
  }

  // 広告詳細(パートナー用)
  async function adDetailPartner(parm: AdDetailPartnerReq): Promise<AdDetailPartnerRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.AD_DETAILPARTNER, null, { params: parm });
      if (response && response?.params) {
        return response.params as AdDetailPartnerRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as AdDetailPartnerRes;
  }

  return {
    adRegister,
    adEdit,
    adDetail,
    adList,
    adDelete,
    adAllianceList,
    adDetailPartner
  }
}