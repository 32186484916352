import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserState } from 'store/types/UserActionTypes';
import { useDispatch } from 'react-redux';
import AuthService from 'api/service/auth/AuthService';
import PartnerService from 'api/service/partners/PartnerService';
import { LoginInfo, AccountInfo, PartnerInfo, BankAccountType, Prefectures } from 'pages/partner/PartnerEdit';
import { loading, unloading } from 'store/actions/loadingActions';
import './Partner.scss';

import LockIcon from '@mui/icons-material/Lock';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';

export default function Partner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authService = AuthService();
  const partnerService = PartnerService();

  const [user, setUser] = useState<UserState | undefined>(undefined);
  const [loginInfo, setLoginInfo] = useState<LoginInfo>({} as LoginInfo);
  const [accountInfo, setAccountInfo] = useState<AccountInfo>({} as AccountInfo);
  const [partnerInfo, setPartnerInfo] = useState<PartnerInfo>({} as PartnerInfo);
  
  useEffect(() => {
    const currentUser = authService.getCurrentUser();
    if (currentUser) {
      setUser(currentUser);
    } else {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    if (user) {
      getPartnerInfo();
    }
  }, [user]);

  function load() {
    dispatch(loading(true));
  }
  
  function unload() {
    dispatch(unloading());
  }

  async function getPartnerInfo() {
    load();
    await partnerService.partnerDetail().then((res) => {
      // ログイン情報セット
      setLoginInfo({
        loginId: res.loginId,
        password: '*******',
        newPassword: '*******',
        newPasswordRe: '*******',
        required: []
      });
      // 口座情報セット
      setAccountInfo({
        bankName: res.bankName || '未設定',
        branchName: res.bankBranchName || '未設定',
        branchNameKana: res.bankBranchNameKana || '未設定',
        branchCode: res.bankBranchCode || '未設定',
        accountType: BankAccountType[res.bankAccountType] || '未設定',
        accountNumber: res.bankAccountNumber || '未設定',
        accountHolderKana: res.bankAccountNameKana || '未設定',
        required: []
      });
      // 登録者情報セット
      setPartnerInfo({
        personId: 0,
        nameAliasType: 0,
        email: res.mailAddress || '未設定',
        companyName: res.name || '未設定',
        companyNameKana: res.nameKana || '未設定',
        // registrationNumber: '未設定',
        manager: res.personName || '未設定',
        tel: res.tel || '未設定',
        fax: res.fax || '未設定',
        postalCode: res.zipCode || '未設定',
        prefecture: Prefectures[res.prefectures] || '未設定',
        address1: res.address1 || '未設定',
        address2: res.address2 || '未設定',
        required: []
      });
      unload();
    })
  }

  function InfoFiled({ title, value }: { title: string, value: string | undefined } ) {
    return(
      <dl key={title}>
        <dt>{title}</dt>
        <dd>
          {value}
        </dd>
      </dl>
    )
  }

  return(
    <section className='partner'>
      <div className='info-area'>
        <div className='left'>
          <div className='login-info info-box'>
            <label>
            <LockIcon className='icon'/><span className='title'>ログイン情報</span>
            </label>
            <InfoFiled title='ログインID' value={loginInfo.loginId}/>
            <InfoFiled title='パスワード' value={loginInfo.password}/>
            <button className='edit-btn' onClick={() => navigate('/partner/edit?t=login')}>変更する</button>
          </div>
          <div className='account-info info-box'>
            <label>
            <AccountBalanceIcon className='icon'/><span className='title'>口座情報</span>
            </label>
            <InfoFiled title='振込先銀行名' value={accountInfo.bankName}/>
            <InfoFiled title='支店名' value={accountInfo.branchName}/>
            <InfoFiled title='支店名(カナ)' value={accountInfo.branchNameKana}/>
            <InfoFiled title='支店名コード' value={accountInfo.branchCode}/>
            <InfoFiled title='口座種別' value={accountInfo.accountType}/>
            <InfoFiled title='口座番号' value={accountInfo.accountNumber}/>
            <InfoFiled title='口座名義(カナ)' value={accountInfo.accountNumber}/>
            <button className='edit-btn' onClick={() => navigate('/partner/edit?t=account')}>変更する</button>
          </div>
        </div>
        <div className='right'>
          <div className='partner-info info-box'>
            <label>
            <PersonIcon className='icon'/><span className='title'>登録者情報</span>
            </label>
            <InfoFiled title='メールアドレス' value={partnerInfo.email}/>
            <InfoFiled title='企業名/個人名' value={partnerInfo.companyName}/>
            <InfoFiled title='企業名/個人名(カナ)' value={partnerInfo.companyNameKana}/>
            {/* <InfoFiled title='連絡請求書発行事業者の登録番号' value={partnerInfo.registrationNumber}/> */}
            <InfoFiled title='担当者名' value={partnerInfo.manager}/>
            <InfoFiled title='TEL' value={partnerInfo.tel}/>
            <InfoFiled title='FAX' value={partnerInfo.fax}/>
            <InfoFiled title='郵便番号' value={partnerInfo.postalCode}/>
            <InfoFiled title='都道府県' value={partnerInfo.prefecture}/>
            <InfoFiled title='住所1(市区町村)' value={partnerInfo.address1}/>
            <InfoFiled title='住所2(番地建物)' value={partnerInfo.address2}/>
            <button className='edit-btn' onClick={() => navigate('/partner/edit?t=partner')}>変更する</button>
          </div>
        </div>
      </div>
    </section>
  )
}