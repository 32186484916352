import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import LogsService from 'api/service/logs/LogsService';
import { LogActionListReq, LogData } from 'api/service/logs/LogsTypes';
import { currency, commaSeparate, formatParmDate } from 'common/utils/StringUtils'
import MonthToggleButton from 'components/button/MonthToggleButton';
import MainTable, { Column } from 'components/table/MainTable';
import { loading, unloading } from 'store/actions/loadingActions';
import { PickerValue } from 'components/calendar/Calendar';
import { cloneDeep } from 'lodash';
import ApexChart, { Chart, ChartOption } from 'components/chart/ApexChart';
import './Home.scss';

import NorthEastRoundedIcon from '@mui/icons-material/NorthEastRounded';

interface CardData {
  title: string;
  value: number;
  subValue: number;
  unit: string;
  type: 'day' | 'month';
}

interface DashboardData {
  name: string;
  clickNum: number;
  cvNum: number;
  cvrSize: number;
  commission: number;
}

export default function Home() {
  const isSp = useMediaQuery({ maxWidth: 767 });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const logsService = LogsService();

  const perPageList: Array<number> = [30, 60, 120];
  const [totalNum, setTotalNum] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(perPageList[0]);

  const [cardList, setCardList] = useState<Array<CardData>>([
    { title: '今日の報酬額', value: 0, subValue: 0, unit: '¥', type: 'day' },
    { title: '今月の報酬額', value: 0, subValue: 0, unit: '¥', type: 'month' },
    { title: '今月のクリック数', value: 0, subValue: 0, unit: '件', type: 'month' },
    { title: '今月の獲得数', value: 0, subValue: 0, unit: '件', type: 'month' },
    { title: '今月のCVR', value: 0, subValue: 0, unit: '%', type: 'month' }
  ]);
  const columns: Array<Column> = [
    { id: 'name', label: '広告名', minWidth: 170 },
    { id: 'clickNum', label: 'クリック数', minWidth: 100, align: 'right', format: (value: number) => value.toLocaleString('en-US') },
    { id: 'cvNum', label: 'CV数', minWidth: 170, align: 'right', format: (value: number) => value.toLocaleString('en-US') },
    { id: 'cvrSize', label: 'CVR', minWidth: 100, align: 'right', format: (value: number) => value + '%' },
    { id: 'commission', label: '報酬額', minWidth: 170, align: 'right', format: (value: number) => '¥' + value.toLocaleString('en-US') }
  ];
  const defaultStart: Date = new Date();
  defaultStart.setDate(defaultStart.getDate() - 1);
  const defaultEnd: Date = defaultStart;
  const [dateRange, setDateRange] = useState<PickerValue>([defaultStart, defaultEnd]);
  const [rows, setRows] = useState<Array<DashboardData>>([]);

  const [commissionUnit, setCommissionUnit] = useState<'day' | 'month'>('month');
  const [cvrUnit, setCvrUnit] = useState<'day' | 'month'>('month');
  
  useEffect(() => {
    getCardInfo();
  }, []);
  
  useEffect(() => {
    getTableInfo();
  }, [dateRange]);

  useEffect(() => {
    // getCommissionGraphInfo();
  }, [commissionUnit]);

  useEffect(() => {
    // getCvrGraphInfo();
  }, [cvrUnit]);
  
  function load() {
    dispatch(loading(true));
  }
  
  function unload() {
    dispatch(unloading());
  }

  function createData(name: string, clickNum: number, cvNum: number, cvrSize: number, commission: number): DashboardData {
    cvrSize = Math.round(cvrSize);
    return { name, clickNum, cvNum, cvrSize, commission };
  }

  // TODO: API待ち-120件制限解除しないとグループ化した時のデータが正しくない
  async function getCardInfo() {
    load();
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const thisMonth = today.getMonth() + 1;
    const lastMonth = (thisMonth - 2 + 12) % 12 + 1;

    const todayStr = today.toLocaleString().slice(0, 10);
    const yesterdayStr = yesterday.toLocaleString().slice(0, 10);
    const thisMonthStr = today.toLocaleString().slice(0, 7);
    const lastMonthStr = new Date(today.getFullYear(), lastMonth - 1).toLocaleString().slice(0, 7);
    
    const firstDayOfLastMonth = new Date(today.getFullYear(), lastMonth - 1, 1);
    const lastDayOfThisMonth = new Date(today.getFullYear(), thisMonth, 0);

    const parm: LogActionListReq = {
      partnerId: 0,
      actionDateTime: formatParmDate(firstDayOfLastMonth, lastDayOfThisMonth),
      row: 120
    };

    await logsService.logActionList(parm).then((res) => {
      let commissionToday: number = 0;
      let commissionYesterday: number = 0;
      let commissionThisMonth: number = 0;
      let commissionLastMonth: number = 0;
      let clickThisMonth: number = 0;
      let clickLastMonth: number = 0;
      let cvThisMonth: number = 0;
      let cvLastMonth: number = 0;
      const updatedCardList = [...cardList];

      res.logs?.forEach((data) => {
        // admitDate処理
        const admitDayStr = new Date(data.admitDateTime).toLocaleString().slice(0, 10);
        const admitMonthStr = new Date(data.admitDateTime).toLocaleString().slice(0, 7);
        if (todayStr === admitDayStr) {
          commissionToday += Number(data.partnerCommissionCost);
        }
        else if (yesterdayStr === admitDayStr) {
          commissionYesterday += Number(data.partnerCommissionCost);
        }
        if (thisMonthStr === admitMonthStr) {
          commissionThisMonth += Number(data.partnerCommissionCost);
          if (data.status === '承認') {
            cvThisMonth++;
          }
        }
        else if (lastMonthStr === admitMonthStr) {
          commissionLastMonth += Number(data.partnerCommissionCost);
          if (data.status === '承認') {
            cvLastMonth++;
          }
        }
        // clickDate処理
        const clickMonthStr = new Date(data.clickDateTime).toLocaleString().slice(0, 7);
        if (thisMonthStr === clickMonthStr) {
          clickThisMonth++;
        }
        else if (lastMonthStr === clickMonthStr) {
          clickLastMonth++;
        }
      })

      updatedCardList[0].value = commissionToday;
      updatedCardList[0].subValue = updatedCardList[0].value - commissionYesterday;
      updatedCardList[1].value = commissionThisMonth;
      updatedCardList[1].subValue = updatedCardList[1].value - commissionLastMonth;
      updatedCardList[2].value = clickThisMonth;
      updatedCardList[2].subValue = updatedCardList[2].value - clickLastMonth;
      updatedCardList[3].value = cvThisMonth;
      updatedCardList[3].subValue = updatedCardList[3].value - cvLastMonth;
      updatedCardList[4].value = Math.round((cvThisMonth / clickThisMonth) * 100);
      updatedCardList[4].subValue = updatedCardList[4].value - Math.round((cvLastMonth / clickLastMonth) * 100);
      setCardList(updatedCardList);
      unload();
      }
    )
  }
  
  async function getTableInfo() {
    load();
    if (Array.isArray(dateRange)) {
      const copyRange = cloneDeep(dateRange.map(date => date !== null ? new Date(date) : null));
      if (copyRange[0] && copyRange[1]) {
        let startDay = new Date(copyRange[0])
        startDay.setHours(0, 0, 0, 0);
        let endDay = new Date(copyRange[1]);
        endDay.setHours(23, 59, 59, 999);
        
        const parm: LogActionListReq = {
          partnerId: 0,
          actionDateTime: formatParmDate(startDay, endDay),
          row: 120
        };
        await logsService.logActionList(parm).then((res) => {
          const tableDataListByGroup: { [key: string]: Array<LogData> } = {};
          const tableDataList: Array<DashboardData> = [];
          // グループ化処理
          res.logs?.forEach((data) => {
            if (!tableDataListByGroup[data.contentName]) {
              tableDataListByGroup[data.contentName] = [];
            }
            tableDataListByGroup[data.contentName].push(data);
          })
          // テーブルデータ作成
          Object.keys(tableDataListByGroup).forEach((contentName: string) => {
            const groupData: Array<LogData> = tableDataListByGroup[contentName];
            let clickNum: number = 0;
            let cvNum: number = 0;
            let cvrSize: number = 0;
            let commission: number = 0;
            groupData.forEach((data) => {
              // admitDate処理
              const admitDay = new Date(data.admitDateTime);
              if (startDay <= admitDay && admitDay <= endDay) {
                commission += Number(data.partnerCommissionCost);
                if (data.status === '承認') {
                  cvNum++; 
                }
              }
              // clickDate処理
              const clickDay = new Date(data.clickDateTime);
              if (startDay <= clickDay && clickDay <= endDay) {
                clickNum++;
              }
            })
            if (cvNum !== 0 && clickNum !== 0) {
              cvrSize = Math.round((cvNum / clickNum) * 100);
            } else {
              cvrSize = 0;
            }
            tableDataList.push(createData(contentName, clickNum, cvNum, cvrSize, commission));
          });
          setTotalNum(tableDataList.length);
          setRows(tableDataList);
          unload();
        })
      }
    }
  }

  // TODO: グラーフデータ作成ロジック作成
  async function getCommissionGraphInfo() {
  }
  // TODO: グラーフデータ作成ロジック作成
  async function getCvrGraphInfo() {
  }

  const dummyCommissionChartData: Chart = {
    height: isSp ? 240 : 308,
    options: {
      chart: ChartOption,
      colors: ['var(--graph2-color)'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 3,
        curve: 'straight',
        fill: {
          type: 'gradient',
          gradient: {
            type: 'horizontal',
            shadeIntensity: 1,
            gradientToColors: ['var(--graph1-color)', 'var(--graph2-color)'],
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100]
          },
        }
      },
      markers: {
        size: 0,
        colors: 'white',
      },
      fill: {
        type: 'gradient',
        gradient: {
          type: 'vertical',
          shadeIntensity: 1,
          gradientToColors: ['var(--graph1-color)', 'var(--graph2-color)'],
          inverseColors: false,
          opacityFrom: 0.3,
          opacityTo: 0,
          stops: [0, 90, 100]
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: 'lightgray'
          },
          formatter: function (val: number) {
            return String((val).toFixed(0));
          },
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: 'lightgray'
          }
        },
        type: 'category',
        categories: [
          '1月', '2月', '3月', '4月', '5月', '6月',
          '7月', '8月', '9月', '10月', '11月', '12月'
        ],
        tickAmount: isSp ? 6 : 'dataPoints'
      },
      tooltip: {
        theme: 'dark',
        shared: true,
        y: {
          formatter: function (val: number) {
            return commaSeparate(val) + '円';
          }
        }
      },
      grid: {
        borderColor: 'var(--shadow-color)'
      },
      legend: {
        show: false
      }
    },
    series: [{
      name: '報酬',
      type: 'area',
      data: [
        15000, 18000, 26000, 13000,
        15000, 19000, 20000, 25000,
        20000, 19000, 22000, 25000,
      ]}
    ],
  };

  const dummyCvrChartData: Chart = {
    height: isSp ? 260 : 330,
    options: {
      chart: ChartOption,
      colors: ['var(--graph1-color)', 'var(--graph3-color)'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [0, 3],
        curve: 'straight',
      },
      markers: {
        size: 0,
        colors: 'white',
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'vertical',
          gradientToColors: ['var(--graph2-color)', 'var(--graph4-color)'],
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        },
      },
      yaxis: [{
        labels: {
          style: {
            colors: 'lightgray'
          }
        }
      }, {
        opposite: true,
        labels: {
          style: {
            colors: 'lightgray'
          }
        }
      }],
      xaxis: {
        labels: {
          style: {
            colors: 'lightgray'
          }
        },
        type: 'category',
        categories: [
          '1月', '2月', '3月', '4月', '5月', '6月',
          '7月', '8月', '9月', '10月', '11月', '12月'
        ],
        tickAmount: isSp ? 6 : 'dataPoints'
      },
      tooltip: {
        theme: 'dark',
        shared: true,
        y: [
          {
            formatter: function (val: number) {
              return commaSeparate(val) + '件';
            }
          },
          {
            formatter: function (val: number) {
              return val + '%';
            }
          }
        ],
      },
      grid: {
        borderColor: 'var(--shadow-color)'
      },
      legend: {
        show: true,
        labels: {
          colors: 'lightgray'
        }
      }
    },
    series: [{
      name: '獲得数',
      type: 'column',
      data: [
        15000, 18000, 26000, 13000,
        15000, 19000, 20000, 25000,
        20000, 19000, 22000, 25000
      ]}
      ,{
      name: 'CVR',
      type: 'line',
      data: [
        20, 38, 26, 13,
        25, 19, 20, 25,
        30, 19, 22, 25
      ]}
    ],
  };

  return(
    <section className='home'>
      <div className='top'>
        {cardList.map((data, idx) => (
          <div className='dash-card' key={idx} onClick={() => navigate(idx === 0 ? '/reports/time' : '/reports/time')}>
            <div className='left'>
              <span className='title'>{data.title}</span>
              <span className={`value ${data.unit === '¥' ? 'reverse' : ''}`}>
                {currency(data.value, false)}
                <span className='unit'>{data.unit}</span>
              </span>
            </div>
            <div className='right'>
              <span className={`comp ${data.subValue > 0 ? 'up' : 'down'}`}>
                <label>{data.type === 'month' ? '前月比' : '前日比'}</label>
                <div className='sub-value'>
                  <NorthEastRoundedIcon className='icon'/>
                  <span className={`value ${data.unit === '¥' ? 'reverse' : ''}`}>
                    {currency(data.subValue, false)}
                    <span className='unit'>{data.unit}</span>
                  </span>
                </div>
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className='middle'>
        <ApexChart title={'報酬額'} unit={['円','']} chartData={dummyCommissionChartData} additional={
          <MonthToggleButton unit={commissionUnit} setUnit={setCommissionUnit}/>
        }/>
        <ApexChart title={cvrUnit === 'month' ? '月別獲得数・CVR' : '日別獲得数・CVR'} unit={['件', '%']} chartData={dummyCvrChartData} additional={
          <MonthToggleButton unit={cvrUnit} setUnit={setCvrUnit}/>
        }/>
      </div>
      <div className='bottom'>
        <MainTable totalNum={totalNum} columns={columns} rows={rows} 
          page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} perPageList={perPageList}
          dateRange={dateRange} setDateRange={setDateRange}/>
      </div>
    </section>
  )
}