import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import PartnerSiteService from 'api/service/partners/PartnerSiteService';
import { PartnerSiteDetailReq } from 'api/service/partners/PartnerSiteTypes';
import { loading, unloading } from 'store/actions/loadingActions';
import './Site.scss';

export interface SiteInfo {
  siteId: number;
  name: string;
  nameAliasType: number;
  outline: string; 
  url: string;
  kickbackUrlAdmit: string;
  os: string; 
  career: string; 
  type: string; 
  memo: string;
  aliveStatus: number;
}

interface SiteInfoTitle {
  id: keyof SiteInfo;
  title: string;
}

export default function SiteDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const partnerSiteService = PartnerSiteService();

  const [siteDetailInfo, setSiteDetailinfo] = useState<SiteInfo>({} as SiteInfo);
  const detailTitle: Array<SiteInfoTitle> = [
    { id: 'name', title: 'サイト名' },
    { id: 'outline', title: 'サイト概要' },
    { id: 'url', title: 'サイトURL' },
    { id: 'kickbackUrlAdmit', title: 'ポストバック通知先' },
    { id: 'os', title: '対応OS' },
    { id: 'career', title: '対応キャリア' },
    { id: 'type', title: 'パートナー種別' },
    { id: 'memo', title: '備考' }
  ]
  const osOptions: Array<string> = ['iOS', 'Andriod', 'PC・その他'];
  const careerOptions: Array<string> = ['docomo', 'au', 'softbank' ,'PC・その他'];
  const typeOptions: Array<string> = ['サイト', 'アプリ', 'その他'];

  useEffect(() => {
    const siteId: number = location.state?.siteId;
    if (siteId) {
      getSiteDetailInfo(siteId);
    } else {
      navigate('/site');
    }
  }, [location]);

  function load() {
    dispatch(loading(true));
  }
  
  function unload() {
    dispatch(unloading());
  }

  async function getSiteDetailInfo(siteId: number) {
    load();
    const parm: PartnerSiteDetailReq = {
      siteId: siteId
    };
    await partnerSiteService.partnerSiteDetail(parm).then((res) => {
      setSiteDetailinfo(res);
    })
    unload();
  }

  const renderCheckboxOptions = (options: Array<string>, item: 'os' | 'career' | 'type') => {
    if (siteDetailInfo[item]) {
      const checkedItem: Array<number> = JSON.parse(siteDetailInfo[item]);
      return options.map((option, idx) => (
        <div key={idx}>
          <input readOnly type='checkbox' checked={checkedItem.includes(idx + 1)}/>
          <label>{option}</label>
        </div>
      ));
    }
  };
  
  return(
    <section className='site-detail'>
      <div className='info-box'>
        <div className='header'>登録情報</div>
        <div className='info'>
          {detailTitle.map((info: SiteInfoTitle) => (
            <div className='info-index' key={info.id}>
              <div className='info-title'>
                {info.title}
              </div>
              <div className='info-id'>
                {
                  info.id === 'os' ? renderCheckboxOptions(osOptions, 'os') :
                  info.id === 'career' ? renderCheckboxOptions(careerOptions, 'career') :
                  info.id === 'type' ? renderCheckboxOptions(typeOptions, 'type') :
                  siteDetailInfo[info.id]
                }
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='edit'>
        <button className='back-btn' onClick={() => navigate('/site')}>戻る</button>
        <button className='edit-btn' onClick={() => navigate('/site/edit', { state: { detail: siteDetailInfo } })}>変更する</button>
      </div>
    </section>  
  );
}