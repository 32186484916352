export function createState(len: number) {
  let characters = '0123456789abcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = 0; i < len; i++) {
    let randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

export function commaSeparate(num: number): string {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function currency(num: number, unitFlg: boolean = true): string {
  let formatNum = num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (formatNum === 'NaN') {
    formatNum = '0';
  }
  return unitFlg ? '¥' + formatNum : formatNum;
}

export function formatParmDate(beforeDate: Date, afterDate: Date): string {
  const beforeYear = beforeDate.getFullYear();
  const beforeMonth = beforeDate.getMonth() + 1;
  const beforeDay = beforeDate.getDate();

  const afterYear = afterDate.getFullYear();
  const afterMonth = afterDate.getMonth() + 1;
  const afterDay = afterDate.getDate();

  return `${beforeYear}年${beforeMonth}月${beforeDay}日 - ${afterYear}年${afterMonth}月${afterDay}日`;
}

export function formatYYYYMMDD(date: Date): string {
  const formatDate = new Intl.DateTimeFormat('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  return formatDate.format(date);
}

export function formatMM(date: Date): string {
  const formatDate = new Intl.DateTimeFormat('ja-JP', {
    month: 'numeric',
  });
  return formatDate.format(date);
}

export function relativeTime(dateValue: Date) {
  const today = new Date();
  const targetDate = new Date(dateValue);
  
  const betweenTime = Math.floor((today.getTime() - targetDate.getTime()) / 1000 / 60);
  
  if (betweenTime < 1) {
    return '数秒前';
  }
  if (betweenTime < 60) {
    return `${betweenTime}分前`;
  }

  const betweenTimeHour = Math.floor(betweenTime / 60);
  if (betweenTimeHour < 24) {
    return `${betweenTimeHour}時間前`;
  }

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  if (betweenTimeDay < 31) {
    return `${betweenTimeDay}日前`;
  }

  const betweenTimeMonth = Math.floor(betweenTime / 60 / 24 / 30);
  if (betweenTimeMonth < 13) {
    return `${betweenTimeMonth}ヶ月前`;
  }

  return `${Math.floor(betweenTimeDay / 365)}年前`;
}