import { UserState } from 'store/types/UserActionTypes';
import AuthService from 'api/service/auth/AuthService';
import { ApiResponse, ApiRoutes } from 'api/Api';
import { LogClickListReq, LogClickListRes } from 'api/service/logs/LogsTypes';
import { LogClickListSpanReq, LogClickListSpanRes } from 'api/service/logs/LogsTypes';
import { LogActionListReq, LogActionListRes } from 'api/service/logs/LogsTypes'
import { LogActionListTimeReq, LogActionListTimeRes} from 'api/service/logs/LogsTypes' 
import { LogActionListPartnerReq, LogActionListPartnerRes } from 'api/service/logs/LogsTypes';
import { LogActionReserveRegisterReq, LogActionReserveRegisterRes } from 'api/service/logs/LogsTypes';
import { LogActionReserveBase64RegisterReq, LogActionReserveBase64RegisterRes } from 'api/service/logs/LogsTypes';
import axios from 'axios';

export default function LogsService() {

  const authService = AuthService();

  const user: UserState = authService.getCurrentUser()!;

  // クリックログ一覧
  async function logClickList(parm?: LogClickListReq): Promise<LogClickListRes> {
    try{ 
      const response: ApiResponse = await axios.post(ApiRoutes.LOG_CLICK_LIST, { params: parm });
      if (response && response?.params) {
        return response.params as LogClickListRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as LogClickListRes;
  }
  
  // クリックログ一覧(期間対応)
  async function logClickListSpan(parm: LogClickListSpanReq): Promise<LogClickListSpanRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.LOG_CLICK_LISTSPAN, { params: parm });
      if (response && response?.params) {
        return response.params as LogClickListSpanRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as LogClickListSpanRes;
  }

  // 成果ログ一覧
  async function logActionList(parm?: LogActionListReq): Promise<LogActionListRes> {
    try {
      parm = { ...parm, partnerId: user?.partnerId as number };
      const response: ApiResponse = await axios.post(ApiRoutes.LOG_ACTION_LIST, null, { params: parm });
      if (response && response?.params) {
        return response.params as LogActionListRes;
      }
    } catch (error) {
      console.error(error);
    }
    return {} as LogActionListRes;
  }

  // 成果ログ一覧時刻対応
  async function logActionListTime(parm?: LogActionListTimeReq): Promise<LogActionListTimeRes> {
    try {
      parm = { ...parm, partnerId: user?.partnerId as number };
      const response: ApiResponse = await axios.post(ApiRoutes.LOG_ACTION_LISTTIME, null, { params: parm });
       if (response && response?.params) {
          return response.params as LogActionListRes;
       }
    } catch(error) {
      console.error(error);
    }
    return {} as LogActionListRes;
  }

  // 成果ログ一覧(パートナー用)
  async function logActionListPartner(parm: LogActionListPartnerReq): Promise<LogActionListPartnerRes> {
    try {
      if (user?.partnerId) {
        parm = { ...parm, partnerId: user.partnerId };
        const response: ApiResponse = await axios.post(ApiRoutes.LOG_ACTION_LISTPARTNER, null, { params: parm});
        if (response && response?.params) {
          return response.params as LogActionListPartnerRes;
        }
      }
    } catch(error) {
      console.error(error);
    }
    return {} as LogActionListPartnerRes;
  }

  // 成果ログ一括更新
  async function logActionReserveRegister(parm: LogActionReserveRegisterReq): Promise<any> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.LOG_ACTION_RESERVE_REGISTER, { params: parm });
      if (response && response?.params) {
        return response.params as LogActionReserveRegisterRes;
      }
    } catch(error) {
      console.error(error);
    }
    return {} as LogActionReserveRegisterRes;
  }

  // 成果ログ一括更新(Base64エンコード版)
  async function logActionReserveBase64Register(parm: LogActionReserveBase64RegisterReq): Promise<any> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.LOG_ACTION_RESERVE_BASE64REGISTER, { params: parm });
      if (response && response?.params) {
        return response.params as LogActionReserveBase64RegisterRes;
      }
    } catch(error) {
      console.error(error);
    }
    return {} as LogActionReserveBase64RegisterRes;
  }
  
  return {
    logClickList,
    logClickListSpan,
    logActionList,
    logActionListTime,
    logActionReserveRegister,
    logActionReserveBase64Register,
    logActionListPartner
  };
}