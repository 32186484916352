import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { getYear } from "date-fns";
import { cloneDeep } from 'lodash';
import { formatParmDate, formatYYYYMMDD } from 'common/utils/StringUtils'
import './Calendar.scss';

import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ja from 'date-fns/locale/ja'
import "react-datepicker/dist/react-datepicker.css";

import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

export type DatePiece = Date | null;
export type PickerValue = DatePiece | [DatePiece, DatePiece];

registerLocale('ja', ja);

/**
 * 日付の選択ツール、選択した範囲を表示させるコンポーネント
 *
 * @param {boolean} view - `true` の場合、選択した日付範囲をビューモードで表示
 * @param {boolean} modal - `true` の場合、モーダル用CSSで表示
 * @param {boolean} vertical - `true` の場合、要素を縦に表示(モーダル、SP用)
 * @param {PickerValue} value - 選択した日付範囲または日付を表す値
 * @param {React.Dispatch<React.SetStateAction<PickerValue>>} onChange - 日付選択が変更されたときに呼び出されるコールバック関数
 */
export default function Calendar({ view, modal, vertical, value, onChange } : { view?: boolean, modal?: boolean, vertical?: boolean, value: PickerValue, onChange: React.Dispatch<React.SetStateAction<PickerValue>> }) {
  const isSp = useMediaQuery({ maxWidth: 767 });

  const rangeList: ('yesterday' | 'today' | 'prevMonth' | 'thisMonth' | 'custom')[] = ['yesterday', 'today', 'prevMonth', 'thisMonth', 'custom'];
  const rangeJp = {
    'yesterday': '昨日',
    'today': '今日',
    'prevMonth': '先月',
    'thisMonth': '今月',
    'custom': 'カスタム'
  }
  const lodash = require('lodash');
  const years = lodash.range(2020, getYear(new Date()) + 1, 1);
  const months = lodash.range(0, 12, 1);

  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<string>(rangeList[0]);
  const [selectedYear, setSelectedYear] = useState<number>();
  const [selectedMonth, setSelectedMonth] = useState<number>();
  const [startDate, setStartDate] = useState<DatePiece>();
  const [endDate, setEndDate] = useState<DatePiece>();
  const [copiedStartDate, setCopiedStartDate] = useState<DatePiece>();
  const [copiedEndDate, setCopiedEndDate] = useState<DatePiece>();

  useEffect(() => {
    const [startValue, endValue] = Array.isArray(value) ? cloneDeep(value) : [value, value];
    setStartDate(startValue);
    setEndDate(endValue);
    if (calendarOpen) {
      setCopiedStartDate(startValue);
      setCopiedEndDate(endValue);
      setSelectedYear(startValue?.getFullYear());
      setSelectedMonth(endValue?.getMonth());
    } else {
      setCopiedStartDate(startDate);
      setCopiedEndDate(endDate);
    }
  }, [calendarOpen, value]);
  
  function rangeClick(range: string) {
    setSelectedRange(range);
    const today = new Date();
    let startDay = today;
    let endDay = today;
    switch(range) {
      case 'yesterday':
        startDay.setDate(today.getDate() - 1);
        endDay = new Date(startDay);
        break;
      case 'today':
        break;
      case 'prevMonth':
        startDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDay = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case 'thisMonth':
        startDay = new Date(today.getFullYear(), today.getMonth(), 1);
        endDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      default:
        return;
    }
    setStartDate(startDay);
    setEndDate(endDay);
    onChange([startDay, endDay]);
  }

  const handleRange = (range: [Date, Date]) => {
    const [startDay, endDay] = range;
    setCopiedStartDate(startDay);
    setCopiedEndDate(endDay);
    if (startDay || endDay) {
      setSelectedRange('custom');
    } else {
      setSelectedRange('');
    }
  };

  function pickerClose() {
    setCalendarOpen(false);
    setStartDate(copiedStartDate);
    setEndDate(copiedEndDate);
    onChange((copiedStartDate && copiedEndDate) ? [copiedStartDate , copiedEndDate] : [null, null]);
  }

  if (view) {
    if (Array.isArray(value)) {
      const [startValue, endValue] = value;
      if (startValue && endValue) {
        return(
          <label>{formatParmDate(startValue, endValue)}</label>
        )
      } else {
        return(<></>)
      }
    } else if (value instanceof Date) {
      return (
        <label>{formatParmDate(value, value)}</label>
      )
    } else {
      return(<></>)
    }
  } else {
    return(
      <div className={vertical ? 'calendar vertical' : 'calendar'}>
        <div className='range'>
          {rangeList.map((range) => (range !== 'custom' &&
            <button key={range} className={range === selectedRange ? 'range-btn active' : 'range-btn'} onClick={() => rangeClick(range)}>
              {rangeJp[range]}
            </button>
          ))}
        </div>
        <div className={`picker ${modal ? 'modal' : ''} ${calendarOpen ? 'active-gradient' : 'close'} ${selectedRange === 'custom' ? 'active-gradient' : ''} ${(startDate && endDate) ? 'active-gradient selected' : ''}`}>
          <EventAvailableRoundedIcon className='icon'/>
          <ReactDatePicker selectsRange disabledKeyboardNavigation locale={ja} shouldCloseOnSelect={false} monthsShown={isSp ? 1 : 2} 
            open={calendarOpen} onInputClick={() => setCalendarOpen(!calendarOpen)} onClickOutside={() => setCalendarOpen(false)}
            selected={copiedStartDate} startDate={copiedStartDate} endDate={copiedEndDate} onChange={handleRange} dateFormat="yyyy-MM-dd"
            value={(startDate && endDate) ? `${formatYYYYMMDD(startDate)} ~ ${formatYYYYMMDD(endDate)}` : '期間選択'}
            renderCustomHeader={({
              monthDate, customHeaderCount,
              changeYear, changeMonth,
              decreaseMonth, increaseMonth
            }) => (
              <div>
                <button aria-label="Previous Month" className={"react-datepicker__navigation react-datepicker__navigation--previous"} style={{ visibility: customHeaderCount === 1 ? 'hidden' : 'visible' }} onClick={decreaseMonth}>
                  <PlayArrowRoundedIcon className='navi-icon'/>
                </button>
                <span className="react-datepicker__current-month" style={{paddingLeft: customHeaderCount === 0 ? '14px' : ''}}>
                  <span className='year'>
                    {monthDate.toLocaleString("ja-JP", {
                      year: "numeric"
                    })}
                    {<>
                    <select value={selectedYear} className='years' 
                      onChange={(e) => {changeYear(parseInt(e.target.value)); setSelectedYear(parseInt(e.target.value))}}>
                      {years.map((year: number) => (
                        <option key={year} value={year}>{year + '年'}</option>
                      ))}
                    </select>
                    <KeyboardArrowDownRoundedIcon className='drop-icon year'/>
                    </>}
                  </span>
                  <span className='month'>
                    {monthDate.toLocaleString("ja-JP", {
                      month: "long"
                    })}
                    {<>
                    <select value={selectedMonth} className='month' 
                      onChange={(e) => {changeMonth(parseInt(e.target.value) - customHeaderCount); setSelectedMonth(parseInt(e.target.value) - customHeaderCount);}}>
                      {months.map((month: number) => (
                        <option key={month} value={month}>{month + 1 + '月'}</option>
                      ))}
                    </select>
                    <KeyboardArrowDownRoundedIcon className='drop-icon month'/>
                    </>}
                  </span>
                </span>
                <button aria-label="Next Month" className={"react-datepicker__navigation react-datepicker__navigation--next"} style={{ visibility: !isSp && customHeaderCount === 0 ? 'hidden' : 'visible' }} onClick={increaseMonth}>
                  <PlayArrowRoundedIcon className='navi-icon'/>
                </button>
              </div>
            )}
          >
            <div className='additional'>
              <button className='active ok-btn' onClick={() => pickerClose()}>
                決定
              </button>
            </div>
          </ReactDatePicker>
        </div>
      </div>
    )
  }
}