export interface PopupState {
  isShow: boolean;
  title?: string;
  contents: string;
  link?: string;
  confirm: boolean;
  isCenter: boolean;
}
  
export enum PopupActionTypes {
  SHOW_POPUP = "SHOW_POPUP",
  HIDE_POPUP = "HIDE_POPUP",
  SHOW_TOP_POPUP = "SHOW_TOP_POPUP",
  SHOW_CENTER_POPUP = "SHOW_CENTER_POPUP",
  SHOW_CENTER_LINK_POPUP = "SHOW_CENTER_LINK_POPUP",
  SHOW_CENTER_CONFIRM_POPUP = "SHOW_CENTER_CONFIRM_POPUP"
}
  
interface ShowPopup {
  type: PopupActionTypes.SHOW_POPUP;
}
  
interface HidePopup {
  type: PopupActionTypes.HIDE_POPUP;
}

interface ShowTopPopup {
  type: PopupActionTypes.SHOW_TOP_POPUP;
  state: {
    contents: string
  };
}
  
interface ShowCenterPopup {
  type: PopupActionTypes.SHOW_CENTER_POPUP;
  state: {
    title: string,
    contents: string
  };
}

interface ShowCenterLinkPopup {
  type: PopupActionTypes.SHOW_CENTER_LINK_POPUP;
  state: {
    title: string,
    contents: string,
    link: string
  };
}

interface ShowCenterConfirmPopup {
  type: PopupActionTypes.SHOW_CENTER_CONFIRM_POPUP;
  state: {
    title: string,
    contents: string
  };
}
  
export type PopupAction = ShowPopup | HidePopup | ShowTopPopup | ShowCenterPopup | ShowCenterLinkPopup | ShowCenterConfirmPopup;