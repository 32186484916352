import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Calendar, { PickerValue } from 'components/calendar/Calendar';
import { cloneDeep } from 'lodash';
import './FilterModal.scss';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

export interface ModalItem {
  title: string;
  datas: ModalData;
}

type ModalData = 
    { type: 'input', value: string }
  | { type: 'radio', value: string, element: Array<{ label: string }> }
  | { type: 'checkbox', value: Array<string> , element: Array<{ label: string, value: boolean }> }
  | { type: 'select', value: Array<{ label: string, value: number }>, element: Array<{ label: string, value: number }> }
  | { type: 'calendar', value: PickerValue }

/**
 * サンプルコード
 * 
 * const sampleItems: Array<ModalItem> = [
 *   { title: '登録サイト', datas: { type: 'input', value: '' } },
 *   { title: '広告', datas: { type: 'select', value: [], element: [
 *     { label: 'テスト広告1', value: 1 },
 *     { label: 'テスト広告2', value: 2 },
 *     { label: 'テスト広告3', value: 3 },
 *   ]}},
 *   { title: 'パートナー種別', datas: { type: 'checkbox', value: [], element: [
 *     { label: 'サイト', value: false },
 *     { label: 'アプリ', value: false },
 *     { label: 'その他', value: false },
 *   ]}},
 *   { title: '登録日タイプ', datas: { type: 'radio', value: '', element: [
 *     { label: '全て' },
 *     { label: '日付指定' },
 *   ]}},
 *   { title: '期間選択', datas: { type: 'calendar', value: [null, null]}}
 * ]
 */

/**
 * フィルターモーダルコンポーネント
 *
 * @param {Array<ModalItem>} items - フィルターアイテムを含む配列(`sampleItems`を参考)
 * @param {React.Dispatch<React.SetStateAction<Array<ModalItem>>>} setItems - フィルターアイテム配列を更新する状態更新関数
 */
export default function FilterModal({ items, setItems }: { items: Array<ModalItem>, setItems: React.Dispatch<React.SetStateAction<Array<ModalItem>>>}) {
  const isSp = useMediaQuery({ maxWidth: 767 });

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [copiedItems, setCopiedItems] = useState<Array<ModalItem>>([]);

  useEffect(() => {
    if (modalOpen) {
      setCopiedItems(cloneDeep(items));
    }
  }, [items, modalOpen]);

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 330,
    color: 'white',
    bgcolor: 'var(--modal-color)',
    borderRadius: '8px',
    padding: isSp ? '16px' : '24px 40px',
  };

  const chipStyle = {
    borderColor: 'var(--btn-focus-gradient)',
    backgroundColor: 'var(--bg-color)',
    color: 'white',
    fontSize: 12,
    p: '0 2px',
    m: '0 2px'
  }
  
  const chipViewrStyle = { ...chipStyle,
    border: '1px solid var(--main-color)',
    height: 'auto',
    p: '6px 12px',
  }

  const selectStyle = {
    backgroundColor: 'white',
    borderRadius: '8px',
    border: '1px solid var(--shadow-color)'
  }

  function close() {
    setItems(items);
    setModalOpen(false);
  }

  function clear() {
    setCopiedItems((prevItems) => {
      const updatedItems: Array<ModalItem> = [...prevItems];
      updatedItems.forEach((item) => {
        if (item.datas.type === 'input') {
          item.datas.value = '';
        } else if (item.datas.type === 'radio') {
          item.datas.value = '';
        } else if (item.datas.type === 'checkbox') {
          item.datas.element = item.datas.element.map((checkbox) => (
            { label: checkbox.label, value: false }
          ));
          item.datas.value = [];
        } else if (item.datas.type === 'select') {
          item.datas.value = [];
        } else if (item.datas.type === 'calendar') {
          item.datas.value = [null, null];
        }
      });
      return updatedItems;
    });
  }  

  function save() {
    setItems(copiedItems);
    setModalOpen(false);
  }

  // フィルター削除機能
  // function deleteChip(chip: any, idx: number) {
  //   const updatedItems = [...items];
  //   const updatedItem = { ...updatedItems[idx] };
  //   updatedItem.datas.value = (updatedItem.datas.value as { label: string; value: number }[]).filter(
  //     (valueChip) => valueChip.value !== chip.value
  //   );
  //   updatedItems[idx] = updatedItem;
  //   setItems(updatedItems);
  // }

  const onChange = (idx: number, itemIdx: number, value: any) => {
    setCopiedItems((prevItems) => {
      const updatedItems: Array<ModalItem> = [...prevItems];
      const updatedItem: ModalItem = { ...updatedItems[idx] };

      if (updatedItem.datas.type === 'input') {
        updatedItem.datas.value = value;
      } else if (updatedItem.datas.type === 'radio') {
        updatedItem.datas.value = value;
      } else if (updatedItem.datas.type === 'checkbox') {
        updatedItem.datas.element = updatedItem.datas.element.map((el, elIndex) => {
          return elIndex === itemIdx ? { ...el, value } : el;
        });
        updatedItem.datas.value = updatedItem.datas.element.filter((ele) => ele.value).map((ele) => ele.label);
      } else if (updatedItem.datas.type === 'select') {
        if (value.length > 0) {
          updatedItem.datas.value = [value[value.length - 1]];
        } else {
          updatedItem.datas.value = value;
        }
      } else if (updatedItem.datas.type === 'calendar') {
        updatedItem.datas.value = [value[0], value[1]]
      }
      updatedItems[idx] = updatedItem;
      return updatedItems;
    });
  };

  const onLabelClick = () => {
    setModalOpen(true);
  }
  
  return(
    <div className='filter-modal'>
      {/* モーダル画面 */}
      {modalOpen &&
        <Modal open={modalOpen} onClose={() => close()}>
          <Box sx={boxStyle}>
            <div className='title'>
              条件設定
            </div>
            {copiedItems.map((item, idx) => (
              <dl key={idx}>
                <dt>{item.title}</dt>
                <dd>
                  {item.datas.type === 'input' ? (
                    <input type='text' value={item.datas.value} onChange={(e) => onChange(idx, idx, e.target.value)}/>
                  )
                  : item.datas.type === 'radio' ? (
                    item.datas.element.map((radio, radioIdx) => (
                      <div className='radio' key={radioIdx}>
                        <input type='radio' name={item.title} value={radio.label} checked={item.datas.value === radio.label} onChange={(e) => onChange(idx, idx, e.target.value)}/>
                        <label>{radio.label}</label>
                      </div>
                    ))
                  )
                  : item.datas.type === 'checkbox' ? (
                    item.datas.element.map((checkbox, checkboxIndex) => (
                      <div className='checkbox' key={checkboxIndex}>
                        <input type='checkbox' value={checkbox.label} checked={checkbox.value} onChange={(e) => onChange(idx, checkboxIndex, e.target.checked)}/>
                        <label>{checkbox.label}</label>
                      </div>
                    ))
                  )
                  : item.datas.type === 'select' ? (
                    <Autocomplete style={selectStyle} multiple fullWidth size='small' limitTags={1} 
                      options={item.datas.element} getOptionLabel={(option) => option.label}
                      value={item.datas.value}
                      onChange={(e, value) => onChange(idx, idx, value)} ChipProps={{sx: chipStyle, color: 'info'}}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      renderInput={(params) => <TextField {...params}/>}
                      renderOption={(props, option, { selected }) => (
                        <li {...props} style={{fontSize: 12, padding: '8px 14px',  display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between'}}>
                          <Checkbox 
                            icon={<RadioButtonUncheckedIcon style={{fontSize: 14}}/>} 
                            checkedIcon={<RadioButtonCheckedIcon color='action' style={{fontSize: 14}}/>}
                            style={{padding: 0, marginRight: 1.25}} checked={selected}
                          />
                          <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{option.label}</span>
                        </li>
                      )}
                    />
                  )
                  : item.datas.type === 'calendar' ? (
                    <div style={{paddingBottom: '4px'}}>
                      <Calendar vertical={true} modal={true} value={item.datas.value} onChange={(e) => onChange(idx, idx, e)}/>
                    </div>
                  )
                  : null}
                </dd>
              </dl>
            ))}
            <div className='buttons'>
              <button className='clear-btn' onClick={() => clear()}>クリア</button>
              <button className='search-btn' onClick={() => save()}>検索</button>
            </div>
          </Box>
        </Modal>
      }
      {/* 選択された条件表示 */}
      <div className='filter-viewer'>
        <div className='filter-list'>
        {items.map((item, idx) => (
          (Array.isArray(item.datas.value) ? item.datas.value.length > 0 && item.datas.value[0] !== null : item.datas.value) &&
          <div key={idx}>
            {item.datas.type === 'input' ? (
              <Chip size='small' className='active-gradient' sx={chipViewrStyle} color='info' onClick={onLabelClick} label={
                <div className='chip-label'>
                  <span className='title'>{item.title}</span>
                  <span className='value'>{item.datas.value}</span>
                </div>
              }/>
            )
            : item.datas.type === 'radio' ? (
              <Chip size='small' className='active-gradient' sx={chipViewrStyle} color='info' onClick={onLabelClick} label={
                <div className='chip-label'>
                  <span className='title'>{item.title}</span>
                  <span className='value'>{item.datas.value}</span>
                </div>
              }/>
            )
            : item.datas.type === 'checkbox' ? (
              <Chip size='small' className='active-gradient' sx={chipViewrStyle} color='info' onClick={onLabelClick} label={
                <div className='chip-label'>
                  <span className='title'>{item.title}</span>
                  <span className='value'>{item.datas.element.map((checkbox, checkboxIdx) => (checkbox.value &&
                    <span key={checkboxIdx}>{checkbox.label}</span>
                  ))}</span>
                </div>
              }/>
            )
            : item.datas.type === 'select' ? (
              <Chip size='small' className='active-gradient' sx={chipViewrStyle} color='info' onClick={onLabelClick} label={
                <div className='chip-label'>
                  <span className='title'>{item.title}</span>
                  <span className='value'>{item.datas.value.map((chip, chipIdx) => (
                    <span key={chipIdx}>{chip.label}</span>
                  ))}</span>
                </div>
              }/>
            )
            : item.datas.type === 'calendar' ? (
              <Chip size='small' className='active-gradient' sx={chipViewrStyle} color='info' onClick={onLabelClick} label={
                <div className='chip-label'>
                  <span className='title'>{item.title}</span>
                  <span className='value'><Calendar view={true} value={item.datas.value} onChange={(e) => onChange(idx, idx, e)}/></span>
                </div>
              }/>
            )
            : null}
          </div>
        ))}
        </div>
        {/* モーダルトリガー */}
        <div className='filter-btn'>
          <button className='toggle-btn' onClick={() => setModalOpen(true)}>条件を変更</button>
        </div>
      </div>
    </div>
  )
}