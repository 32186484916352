import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from 'react-helmet-async';
import { RootState } from "store/reducers";
import { showPopup, hidePopup } from "store/actions/popupActions";
import { UserState } from "store/types/UserActionTypes";
import AuthService from 'api/service/auth/AuthService';
import Loading from 'components/backdrop/Loading';
import Popup from 'components/popup/Popup';
import HeaderNav from 'components/nav/HeaderNav';
import SideNav, { MenuItem } from 'components/nav/SideNav';
import Home from 'pages/home/Home';
import Empty from 'components/empty/Empty';
import Login from 'pages/login/Login';
import Site from 'pages/site/Site';
import SiteDetail from 'pages/site/SiteDetail';
import SiteEdit from 'pages/site/SiteEdit';
import Logs from 'pages/logs/Logs';
import Partner from 'pages/partner/Partner';
import PartnerEdit from 'pages/partner/PartnerEdit';
import ReportsTime from 'pages/reports/ReportsTime';
import ReportsAd from 'pages/reports/ReportsAd';
import Status from 'pages/status/Status';
import logo from 'assets/icon/logo.svg';
import './App.scss';

import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';


export default function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const authService = AuthService();

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [mini, setMini] = useState<boolean>(true);
  const [currentPath, setCurrentPath] = useState<string>('');
  const [user, setUser] = useState<UserState | undefined>(undefined);
  const loading = useSelector((state: RootState) => state.loading);
  const popup = useSelector((state: RootState) => state.popup);
  
  type PathMap = {
    [key: string]: {
      title: string;
      description: string;
    };
  };
  
  const pathMap: PathMap = {
    'login': { title: 'ログイン', description: 'アカウントでログインしてサービスを利用してください' },
    'reports/time': { title: '時系列レポート', description: '時系列レポート' },
    'reports/ad': { title: 'サイト広告列レポート', description: 'サイト広告列レポート' },
    'logs': { title: '獲得ログ', description: '獲得ログ' },
    'status': { title: '提携状況', description: '提携状況' },
    'site': { title: 'サイト一覧', description: 'サイト一覧' },
    'partner': { title: 'パートナー情報', description: 'パートナー情報' },
    'partner/edit': { title: 'パートナー情報編集', description: 'パートナー情報編集' },
  };

  const menuItem: Array<MenuItem> = [
    {
      category: 'レポート',
      categoryPath: 'reports',
      categoryIcon: <SignalCellularAltRoundedIcon/>,
      items: [
        { path: 'reports/time', title: '時系列レポート' },
        { path: 'reports/ad', title: 'サイト広告列レポート' },
      ]
    },{
      category: '獲得ログ',
      categoryPath: 'logs',
      categoryIcon: <ArticleRoundedIcon/>,
      items: []
    },{
      category: '提携状況',
      categoryPath: 'status',
      categoryIcon: <InsertLinkRoundedIcon/>,
      items: []
    },{
      category: 'サイト一覧',
      categoryPath: 'site',
      categoryIcon: <LanguageRoundedIcon/>,
      items: []
    },{
      category: 'パートナー管理',
      categoryPath: 'partner',
      categoryIcon: <PersonRoundedIcon/>,
      items: []
    }
  ]
  
  // ログインしてない場合ログイン画面に遷移
  useEffect(() => {
    if (!authService.getCurrentUser()) {
      navigate('/login');
    }
  }, []);

  // ユーザーの実際ウィンドウズ高さに沿ってvh変更
  useEffect(() => {
    const updateVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    updateVh();
    window.addEventListener('resize', updateVh);
    return () => {
      window.removeEventListener('resize', updateVh);
    };
  }, []);

  // ポップアップ表示ロジック、上段ポップアップの場合2秒位表示
  useEffect(() => {
    if (popup.isShow) {
      if (popup.isCenter) {
        dispatch(showPopup());
      } else {
        dispatch(showPopup());
        const timer = setTimeout(() => {
          dispatch(hidePopup());
        }, 2200);
        return () => clearTimeout(timer);
      }
    }
  }, [popup.isShow, popup.isCenter, popup.confirm]);
  
  // URL変更による処理
  useEffect(() => {
    window.scrollTo({ top: -100 });
    const currentPath = location.pathname;
    const path = currentPath.split('/');
    if (path.length > 2) {
      setCurrentPath(path.slice(1).join('/'));
    } else {
      setCurrentPath(path[1]);
    }
    const currentUser = authService.getCurrentUser();
    if (currentUser) {
      setUser(currentUser);
    } else {
      navigate('/login');
    }
  }, [location.pathname]);

  function Header() {
    const currentPathData = pathMap[currentPath];
    return(
      <header>
        <Helmet>
          <title>{currentPathData?.title ? currentPathData.title + ' - AFAD' : 'AFAD'}</title>
          <meta property="og:title" content={currentPathData?.title ? currentPathData.title + ' - AFAD' : 'AFAD'}/>
          <meta name="description" content={currentPathData?.description ? currentPathData.description : 'AFAD custom'}/>
          <meta property="og:description" content={currentPathData?.description ? currentPathData.description : 'AFAD custom'}/>
          <link rel="canonical" href={`https://afad-dad.com/${currentPath}`}/>
        </Helmet>
        <div className='header-main'>
          <img src={logo} className='logo' onClick={() => navigate('/')}/>
          <div className='side'>
            <HeaderNav userName={user?.userName} open={menuOpen} setOpen={setMenuOpen}/>
          </div>
        </div>
      </header>
    )
  }

  function Footer() {
    return(
      <footer>
        <div className='footer-main'>
          <div className='side'>
            Copyright © Dad株式会社
          </div>
        </div>
      </footer>
    )
  }

  return(
    <>
      <Loading dark={loading.isDark} show={loading.isShow}/>
      <Popup title={popup.title} contents={popup.contents} link={popup.link} center={popup.isCenter} confirm={popup.confirm}/>
      <Header/>
      {currentPath !== 'login' && <SideNav menuItem={menuItem} currentPath={currentPath} mini={mini} setMini={setMini} open={menuOpen} setOpen={setMenuOpen}/>}
      <main>
        <Routes>
          <Route path="/login" element={<Login/>}/>
          <Route path="/" element={<Home/>}/> {/* 実装不可:API(MAX120件制限)修正待ち */}
          <Route path="/reports/time" element={<ReportsTime/>}/> {/* 実装不可:該当API待ち */}
          <Route path="/reports/ad" element={<ReportsAd/>}/> {/* 実装不可:該当API待ち */}
          <Route path="/logs" element={<Logs/>}/>
          <Route path="/status" element={<Status/>}/>
          <Route path="/site" element={<Site/>}/>
            <Route path="/site/detail" element={<SiteDetail/>}/>
            <Route path="/site/edit" element={<SiteEdit/>}/>
          <Route path="/partner" element={<Partner/>}/>
            <Route path="/partner/edit" element={<PartnerEdit/>}/>
          <Route path='/*' element={<Empty/>}/>
        </Routes>
      </main>
      <Footer/>
    </>
  );
}