import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from 'assets/icon/logo.svg';
import AuthService from 'api/service/auth/AuthService';
import { loading, unloading } from 'store/actions/loadingActions';
import { showCenterPopup } from 'store/actions/popupActions';
import './Login.scss';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const authService = AuthService();
  
  const [passwordType, setPasswordType] = useState<{type: string, visible: boolean}>({
    type: 'password',
    visible: false
  });
  
  const [id, setId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errMsg, setErrMsg] = useState<string>("");

  useEffect(() => {
    // TODO: リリース時削除必要
    setId('dadtest');
    setPassword('@pK.zJAQY0x1v/jP6_Zmq2@EiFNMnmmo');
    
    unload();
    if (authService.getCurrentUser()) {
      navigate(-1);
    }
  }, []);

  function load() {
    dispatch(loading(true));
  }
  
  function unload() {
    dispatch(unloading());
  }
  
  async function login() {
    errReset();
    load();
    await authService.login(id, password).then(data => {
      if (data) {
        loginSuccess();
      } else {
        setErrMsg('ログイン情報をもう一度ご確認ください');
        unload();
      }
    });
  }

  function loginSuccess() {
    const prev = location.state?.prev;
    if (prev) {
      navigate(prev, { replace: true });
    } else {
      navigate('/', { replace: true });
    }
    unload();
  }

  function passwordTypeHandler(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    setPasswordType(() => {
      if (!passwordType.visible) {
        return { type: 'text', visible: true };
      }
      return { type: 'password', visible: false };
    })
  }

  function onSubmitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    errReset();
    if (id === "" || password === "") {
      setErrMsg("IDとパスワードを入力してください");
      return;
    }
    login();
  }

  function errReset() {
    setErrMsg('');
  }

  return(
    <section className='login'>
      <form className='login-form' onSubmit={onSubmitHandler}>
        <img className='logo' src={logo}/>
        <div className='id'>
          <label>ID</label>
          <input type='text' id='id' className={id.length > 0 ? 'entered' : ''} placeholder='IDを入力' value={id} onChange={(e) => {setId(e.target.value); errReset();}}/>
        </div>
        <div className='password'>
          <label>パスワード</label>
          <input type={passwordType.type} id='password' autoComplete='current-password' className={password.length > 0 ? 'entered' : ''} placeholder='パスワードを入力' value={password} onChange={(e) => {setPassword(e.target.value); errReset();}}/>
          {password.length > 0 &&
            <span className='visible' onClick={passwordTypeHandler}>
              { passwordType.visible ? <VisibilityIcon sx={{ fontSize: 16 }}/> : <VisibilityOffIcon sx={{ fontSize: 16 }}/> }
            </span>
          }
        </div>
        <div className='recover'>
          <span className='text' onClick={() => dispatch(showCenterPopup('', 'お手数おかけいたしますが、\nDad株式会社までお問い合わせください'))}>
            パスワード再発行
          </span>
        </div>
        <div className='err-area'>
          {errMsg && <p className='errmsg'>{errMsg}</p>}     
        </div>
        <button type='submit' className='signin'>ログイン</button>
        <p className='signup'>
          アカウントを持っていない場合は<span onClick={() => dispatch(showCenterPopup('', 'お手数おかけいたしますが、\nDad株式会社までお問い合わせください'))}>新規登録</span>から
        </p>
      </form>
    </section>
  )
}