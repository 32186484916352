import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";
import Calendar, { PickerValue } from 'components/calendar/Calendar';
import './MainTable.scss';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export interface Column {
  id: string;
  label: string;
  hide?: boolean;
  width?: number;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
}

/**
 * ページネーション付きテーブルコンポーネント
 *
 * @param {boolean} menuFlg - 各行にメニューを表示するかどうか
 * @param {number} totalNum - 全体データ数
 * @param {Array<Column>} columns - 表示するテーブルの列の配列
 * @param {Array<any>} rows - 表示するテーブルの行データの配列
 * @param {number} page - 現在のページ番号
 * @param {React.Dispatch<React.SetStateAction<number>>} setPage - ページ番号を設定する関数
 * @param {number} perPage - ページごとの行数
 * @param {React.Dispatch<React.SetStateAction<number>>} setPerPage - ページごとの行数を設定する関数
 * @param {Array<number>} perPageList - ページごとの行数のリスト
 * @param {PickerValue} dateRange - 日付範囲
 * @param {React.Dispatch<React.SetStateAction<PickerValue>>} setDateRange - 日付範囲を設定する関数
 */
export default function MainTable({ menuFlg, totalNum, columns, rows, page, setPage, perPage, setPerPage, perPageList, dateRange, setDateRange }: 
    { menuFlg?: boolean, totalNum: number, columns: Array<Column>, rows: Array<any>, 
      page?: number, setPage?: React.Dispatch<React.SetStateAction<number>>, perPage?: number, setPerPage?: React.Dispatch<React.SetStateAction<number>>, perPageList?: Array<number>,
      dateRange?: PickerValue, setDateRange?: React.Dispatch<React.SetStateAction<PickerValue>> }) {
  const isSp = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();

  const menuList: Array<{label: string, path: string}> = [
    { label: '登録詳細', path: '/site/detail'},
    { label: '広告一覧', path: '/status'},
    { label: '時系列レポート', path: '/reports/time'},
    { label: 'サイト広告別レポート', path: '/reports/ad'}
  ];
  
  const [selectedId, setSelectedId] = useState<null | number>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isSelectFocused, setIsSelectFocused] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, [page])

  const handleClick = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, siteId: number) => {
    setAnchorEl(e.currentTarget);
    setSelectedId(siteId);
  };

  const handleSelectFocus = () => {
    setIsSelectFocused(true);
  };
  
  const handleSelectBlur = () => {
    setIsSelectFocused(false);
  };

  const menuClick = () => {
    setAnchorEl(null);
  };

  const pageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    if (setPage) {
      setPage(page);
    }
  };
  
  const perPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (setPage && setPerPage) {
      setPerPage(parseInt(e.target.value, 10));
      setPage(1);
    }
  };
  
  return(
    <>
    {perPageList && 
    <div className='output-num'>
      <div className='left'>
        {dateRange && setDateRange && <Calendar vertical={isSp} value={dateRange} onChange={(e) => setDateRange(e)}/>}
      </div>
      <div className='right'>
        <div className='title'>表示件数</div>
        <div className={`selecter ${isSelectFocused ? 'active-gradient' : ''}`}>
          <select className={`selecter ${isSelectFocused ? 'active-gradient' : ''}`} onChange={(e) => perPageChange(e)} onFocus={handleSelectFocus} onBlur={handleSelectBlur}>
            {perPageList.map((perNum) => (
              <option key={perNum} className={perPage === perNum ? 'per active' : 'per'}>{perNum + '件'}</option>
              ))}
          </select>
        </div>
      </div>
    </div>
    }
    <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: 'unset', boxShadow: 'unset', borderRadius: '8px', border: '1px solid var(--shadow-color)' }}>
      <Menu anchorEl={anchorEl} open={open} onClose={menuClick}>
        {menuList.map((menu) => (
          <MenuItem key={menu.label} onClick={() => navigate(menu.path, { state: { siteId: selectedId } })}>{menu.label}</MenuItem>
        ))}
      </Menu>
      <TableContainer>
        <Table className='main-table' stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {menuFlg && 
                <TableCell style={{ width: '20px', padding: '6px 8px 6px 16px', borderRight: 'none' }}/>
              }
              {columns.map((column: Column) => (
                column.hide ? <></> :
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, width: column.width }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ?
              // rows.slice((page - 1) * perPage, page * perPage).map((row, idx) => {
              rows.map((row, idx) => {
                return (
                  <TableRow hover tabIndex={-1} key={idx}>
                    {menuFlg && 
                      <TableCell onClick={(e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => handleClick(e, row.siteId)} key={idx} align={'center'} style={{ width: '20px', padding: '6px 8px 6px 16px', borderRight: 'none' }}>
                        <MoreHorizIcon className='icon more'/>
                      </TableCell>
                    }
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        column.hide ? <></> :
                        <TableCell key={column.id} align={column.align}>
                          <div className='row-text'>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value
                            }
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
              :
              <TableRow hover>
                <TableCell className='no-data' align='center' colSpan={menuFlg ? columns.length + 1 : columns.length}>
                  該当するデータがありません
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    {perPage && Math.ceil(totalNum / perPage) > 1 && <Pagination className='pagination' variant='outlined' count={Math.ceil(totalNum / perPage)} page={page} onChange={pageChange} shape="rounded"/>}
    </>
  )
}