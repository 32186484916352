import React, { useEffect } from 'react'
import axios, { AxiosError } from 'axios';
import { useDispatch } from "react-redux";
import { showCenterPopup } from "store/actions/popupActions";

export function AxiosClientProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const dispatch = useDispatch();

  // クリーンアップ
  useEffect(() => {
    return () => {
      axios.interceptors.request.eject(requestInterceptors)
      axios.interceptors.response.eject(responseInterceptor)
    }
  }, [])
 
  // デフォルト設定
  axios.defaults.baseURL = `${process.env.REACT_APP_ADDRESS}:${process.env.REACT_APP_VIEW_PORT}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

  // リクエストインターセプター
  const requestInterceptors = axios.interceptors.request.use(
    (config) => {
      return config;
    }
  ); 

  // レスポンスインターセプター
  const responseInterceptor = axios.interceptors.response.use(
    (response) => {
      if (response.data.code === 200) {
        return response.data;
      } else {
        const errorMessages = [];
        const errors = response.data.errors;
        for (const fieldName in errors) {
          if (errors.hasOwnProperty(fieldName)) {
            const errorMessage = errors[fieldName];
            errorMessages.push(`${errorMessage}`);
          }
        }
        dispatch(showCenterPopup(response.data.code, errorMessages.join('\n')));
        return response
      }
    },
    (error) => {
      switch (error.response?.status) {
        default:
          handleDefaultError(error);
          break;
      }
      return Promise.reject(error);
    }
  )

  function handleDefaultError(error: AxiosError): void {
    console.error('Error response:', error.response);
  }
 
  return <>{children}</>;
}