import { ApexOptions } from "apexcharts";
import ReactApexChart from 'react-apexcharts';
import './ApexChart.scss';

export const ChartOption: ApexChart = {
  toolbar: {
    show: true,
    offsetX: 4,
    tools: {
      download: false, // '<svg class="apex-download" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="SystemUpdateAltRoundedIcon"><path d="m12.35 15.65 2.79-2.79c.31-.31.09-.85-.35-.85H13V4c0-.55-.45-1-1-1s-1 .45-1 1v8H9.21c-.45 0-.67.54-.35.85l2.79 2.79c.19.2.51.2.7.01zM21 3h-5.01c-.54 0-.99.45-.99.99 0 .55.45.99.99.99H20c.55 0 1 .45 1 1v12.03c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V5.99c0-.55.45-1 1-1h4.01c.54 0 .99-.45.99-.99 0-.55-.45-1-.99-1H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path></svg>'
      selection: false,
      zoom: false,
      zoomin: false,
      zoomout: false,
      pan: false,
      reset: false,
    },
  },
};

export interface Chart {
  height: number | string;
  options: ApexOptions;
  series: ApexOptions['series'];
}

/**
 * ApexCharライブラリ用コンポーネント
 */
export default function ApexChart({title, unit, chartData, reportFlg, additional}: {title: string, unit?: [string, string], chartData: Chart, reportFlg?: boolean, additional?: React.ReactElement<any, any>}) {
  return(
    <div className='graph'>
      <div className={reportFlg ? 'additional report' : 'additional'}>
        <div className='left'>
          <div className='title'>{title}</div>
        </div>
        {additional}
      </div>
      {unit &&
      <div className='graph-unit'>
        <div className='left'>
          {unit[0] && ('(' + unit[0] + ')')}
        </div>
        <div className='right'>
          {unit[1] && ('(' + unit[1] + ')')}
        </div>
      </div>
      }
      <ReactApexChart options={chartData.options} series={chartData.series} height={chartData.height}/>
    </div>
  )
}