import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { loading, unloading } from 'store/actions/loadingActions';
import FilterModal, { ModalItem } from 'components/modal/FilterModal';
import { LogActionListReq } from 'api/service/logs/LogsTypes';
import MainTable, { Column } from 'components/table/MainTable'
import LogsService from 'api/service/logs/LogsService';
import AdService from 'api/service/ad/AdService';
import PartnerSiteService from 'api/service/partners/PartnerSiteService';
import { AdAllianceListReq } from 'api/service/ad/AdTypes';
import { formatParmDate } from 'common/utils/StringUtils'
import './Logs.scss';

interface LogsData {
  clickDate: string;
  admitDate: string;
  actionDate: string;
  contentName: string;
  siteName: string;
  actionOs: string;
  actionReferrer: string;
  partnerCommission: number;
  status: string;
  info1: string;
  info2: string;
  info3: string;
  info4: string;
  info5: string;
}

export default function Logs() {
  const isSp = useMediaQuery({ maxWidth: 767 });
  const dispatch = useDispatch();

  const logsService = LogsService();
  const adService = AdService();
  const partnerSiteService = PartnerSiteService();

  const perPageList: Array<number> = [30, 60, 120];
  const [totalNum, setTotalNum] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(perPageList[0]);

  const today: Date = new Date();
  const lastMonth: Date = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

  const [modalItem, setModalItem] = useState<Array<ModalItem>>([
    { title: '指定期間', datas: { type: 'calendar', value: [lastMonth, today]}},
    { title: 'パートナーサイト', datas: { type: 'select', value: [], element: [] } },
    { title: '広告', datas: { type: 'select', value: [], element: [] } }
  ]);
  const siteItemIdx: number = 1;
  const adItemIdx: number = 2;
  const columns: Array<Column> = [
    { id: 'clickDate', label: 'クリック日時', hide: isSp, minWidth: 80, align: 'center' },
    { id: 'admitDate', label: '承認日時', minWidth: 80, align: 'center' },
    { id: 'actionDate', label: '登録日時', hide: isSp, minWidth: 80, align: 'center' },
    { id: 'contentName', label: '広告名', minWidth: 200 },
    { id: 'siteName', label: 'パートナーサイト名', hide: isSp, minWidth: 140 },
    { id: 'actionOs', label: 'OS', hide: isSp, minWidth: 80, align: 'center' },
    { id: 'actionReferrer', label: 'リファラ', hide: isSp, minWidth: 200 },
    { id: 'partnerCommission', label: '報酬額', minWidth: 80, align: 'right', format: function (val: number) {return String((val).toFixed(0)) + '円';}},
    { id: 'status', label: 'ステータス', hide: isSp, minWidth: 80, align: 'center' },
    { id: 'info1', label: 'CL付加情報1', hide: isSp, minWidth: 80 },
    { id: 'info2', label: 'CL付加情報2', hide: isSp, minWidth: 80 },
    { id: 'info3', label: 'CL付加情報3', hide: isSp, minWidth: 80 },
    { id: 'info4', label: 'CL付加情報4', hide: isSp, minWidth: 80 },
    { id: 'info5', label: 'CL付加情報5', hide: isSp, minWidth: 80 }
  ];
  const [rows, setRows] = useState<Array<LogsData>>([]);

  useEffect(() => {
    getMeodalInfo();
  }, []);
  
  useEffect(() => {
    getLogTable();
  }, [page, perPage]);

  useEffect(() => {
    getLogTable();
  }, [modalItem]);
  
  function load() {
    dispatch(loading(true));
  }
  
  function unload() {
    dispatch(unloading());
  }

  function createData(
    clickDate: string,
    admitDate: string,
    actionDate: string,
    contentName: string,
    siteName: string,
    actionOs: string,
    actionReferrer: string,
    partnerCommission: number,
    status: string,
    info1: string,
    info2: string,
    info3: string,
    info4: string,
    info5: string
    ): LogsData {
    return { 
      clickDate, 
      admitDate,
      actionDate,
      contentName,
      siteName,
      actionOs,
      actionReferrer,
      partnerCommission,
      status,
      info1,
      info2,
      info3,
      info4,
      info5 
    };
  }

  async function getMeodalInfo() {
    load();
    const filterSiteList: Array<{label: string, value: number}> = [];
    const filterAdList: Array<{label: string, value: number}> = [];
  
    await partnerSiteService.partnerSiteList().then((res) => {
      res.sites?.forEach((site) => {
        filterSiteList.push({ label: site.siteName, value: Number(site.siteId) })
        const parm: AdAllianceListReq = {
          partnerSiteId: site.siteId,
          row: 120
        }
        adService.adAllianceList(parm).then((res) => {
          res.contents?.forEach((content) => {
            const contentIdx = filterAdList.findIndex(item => item.value === Number(content.contentId));
            if (contentIdx === -1) {
              filterAdList.push({ label: content.contentName, value: Number(content.contentId) });
            }
          })
        })
      })
    })
  
    setModalItem((prev) => {
      const modalItems = [...prev];
      const siteItem = modalItems[siteItemIdx];
      const adItem = modalItems[adItemIdx];
      if (siteItem.datas.type === 'select') {
        modalItems[siteItemIdx] = { ...siteItem,
          datas: { ...siteItem.datas,
            element: filterSiteList
          }
        };
      }
      if (adItem.datas.type === 'select') {
        modalItems[adItemIdx] = { ...adItem,
          datas: { ...adItem.datas,
            element: filterAdList
          }
        };
      }
      return modalItems;
    });
    unload();
  }

  async function getLogTable() {
    load();
    let actionDateTime: string | undefined = undefined;
    const seletedDate = modalItem[0].datas.value;
    const seletedSite = modalItem[siteItemIdx].datas.value as Array<{ label: string, value: number }>;
    const seletedAd = modalItem[adItemIdx].datas.value as Array<{ label: string, value: number }>;
    if (Array.isArray(seletedDate)) {
      const [startValue, endValue] = seletedDate;
      actionDateTime = formatParmDate(startValue as Date, endValue as Date);
    }
    const parm: LogActionListReq = {
      actionDateTime: actionDateTime,
      siteId: seletedSite[0]?.value,
      contentId: seletedAd[0]?.value,
      row: perPage,
      page: page
    }
    await logsService.logActionList(parm).then((res) => {
      const tableDataList: Array<LogsData> = [];
      setTotalNum(res.totalNum);
      res.logs.forEach((data) =>{
        const clickPartnerInfo = JSON.parse(data.clickPartnerInfo);
        tableDataList.push(createData(
          data.clickDateTime, 
          data.admitDateTime,
          data.actionDateTime,
          data.contentName,
          data.siteName,
          data.actionOs,
          data.actionReferrer,
          Number(data.partnerCommissionCost),
          data.status,
          clickPartnerInfo['param1'],
          clickPartnerInfo['param2'],
          clickPartnerInfo['param3'],
          clickPartnerInfo['param4'],
          clickPartnerInfo['param5']
        ))
      })
      setRows(tableDataList);
    })
    unload();
  }

  return(
    <section className='logs'>
      <div className='top'>
       <FilterModal items={modalItem} setItems={setModalItem}/>
      </div>
      <div className='bottom'>
        <MainTable totalNum={totalNum} columns={columns} rows={rows}
          page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} perPageList={perPageList}/>
      </div>
    </section>
  )
}