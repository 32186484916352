import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { SiteInfo } from 'pages/site/SiteDetail';
import PartnerSiteService from 'api/service/partners/PartnerSiteService';
import { PartnerSiteDetailRes, PartnerSiteEditReq } from 'api/service/partners/PartnerSiteTypes';
import { loading, unloading } from 'store/actions/loadingActions';
import { showCenterPopup } from 'store/actions/popupActions';
import './Site.scss'

interface EditInfo {
  id: keyof SiteInfo;
  title: string;
  type: string;
  options?: Array<string>
}

export default function SiteEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const partnerSiteService = PartnerSiteService();

  const [siteDetailInfo, setSiteDetailinfo] = useState<SiteInfo>({
    siteId: 0,
    name: '',
    nameAliasType: 0,
    outline: '',
    url: '',
    kickbackUrlAdmit: '',
    os: '',
    career: '',
    type: '',
    memo: '',
    aliveStatus: 0,
  });

  useEffect(() => {
    const detail: PartnerSiteDetailRes = location.state?.detail;
    if (detail) {
      setSiteDetailinfo(detail);
    } else {
      navigate('/site');
    }
  }, [location]);

  const detailInfo: Array<EditInfo> = [
    { id: 'name', title: 'サイト名', type: 'text' },
    { id: 'outline', title: 'サイト概要', type: 'textarea' },
    { id: 'url', title: 'サイトURL', type: 'text' },
    { id: 'os', title: '対応OS', type: 'checkbox', options: ['iOS', 'Andriod', 'PC・その他'] },
    { id: 'career', title: '対応キャリア', type: 'checkbox', options: ['docomo', 'au', 'softbank', 'PC・その他'] },
    { id: 'type', title: 'パートナー種別', type: 'checkbox', options: ['サイト', 'アプリ', 'その他'] },
    { id: 'memo', title: '備考', type: 'textarea' }
  ]
  const optionsValue: { [key: string]: { [key: string]: number } } = {
    'os': { 'iOS': 1, 'Andriod': 2, 'PC・その他': 3 },
    'career': { 'docomo': 1, 'au': 2, 'softbank': 3, 'PC・その他': 4 },
    'type': { 'サイト': 1, 'アプリ': 2, 'その他': 3 }
  };  

  function load() {
    dispatch(loading(true));
  }
  
  function unload() {
    dispatch(unloading());
  }

  const textChange = (value: string, name: string) => {
    setSiteDetailinfo((prev) => ({ ...prev, [name]: value }));
  };

  const checkboxChange = (checked: boolean, option: string, item: string) => {
    if (item === 'os' || item === 'career' || item === 'type') {
      setSiteDetailinfo((prevInfo) => {
        const target: Array<number> = JSON.parse(prevInfo[item]);
        const targetValue: number = optionsValue[item][option];
        // チェック処理
        if (checked) {
          if (!target.includes(targetValue)) {
            target.push(targetValue);
          }
        } else {
          const indexToRemove = target.indexOf(targetValue);
          if (indexToRemove !== -1) {
            target.splice(indexToRemove, 1);
          }
        }
        // 昇順でソート
        target.sort((a, b) => a - b);
        return { ...prevInfo, [item]: JSON.stringify(target) };
      });
    }
  };

  async function save() {
    load();
    const parm: PartnerSiteEditReq = {
      siteId: siteDetailInfo.siteId,
      partnerId: 0,
      name: siteDetailInfo.name,
      nameAliasType: siteDetailInfo.nameAliasType as 0 | 1,
      outline: siteDetailInfo.outline,
      url: siteDetailInfo.url,
      career: JSON.parse(siteDetailInfo.career),
      os: JSON.parse(siteDetailInfo.os),
      type: JSON.parse(siteDetailInfo.type),
      memo: siteDetailInfo.memo,
      aliveStatus: siteDetailInfo.aliveStatus as 0 | 1 | 2
    };
    await partnerSiteService.partnerSiteEdit(parm).then((res) => {
      if (siteDetailInfo.siteId === res.siteId) {
        dispatch(showCenterPopup('変更完了', '上記の内容が保存されました'));
        navigate('/site/detail', { state: { siteId: res.siteId } });
      }
    })
    unload();
  }

  const renderCheckboxOptions = (options: Array<string>, item: 'os' | 'career' | 'type') => {
    if (siteDetailInfo[item]) {
      const checkedItem: Array<number> = JSON.parse(siteDetailInfo[item]);
      return options.map((option, idx) => (
        <div key={idx}>
          <input type='checkbox' checked={checkedItem.includes(idx + 1)} onChange={(e) => checkboxChange(e.target.checked, option, item)}/>
          <label>{option}</label>
        </div>
      ));
    }
  };
  
  return(
    <section className='site-editer'>
      <div className='editer-box'>
        <div className='header'> サイト変更 </div>
        {/* input - サイト名、URL / textarea - サイト概要、備考 / checkbox - OS、キャリア、種別 */}
        <div className='regist-edit'>
          {detailInfo.map((item: EditInfo) => (
            <div className='edit-info' key={item.id}>
              <div className='title'>
                {item.title}
              </div>
              <div className='contents'>
                {item.type === 'checkbox' ? 
                  renderCheckboxOptions(item.options as Array<string>, item.id as 'os' | 'career' | 'type')
                : item.type === 'textarea' ? 
                  <textarea className='textarea' value={siteDetailInfo[item.id]} placeholder='400字以内で入力してください' onChange={(e) => textChange(e.target.value, item.id)}/>
                :
                  <input className='text' type='text' value={siteDetailInfo[item.id]} placeholder='400字以内で入力してください' onChange={(e) => textChange(e.target.value, item.id)}/>
                }
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='buttons'>
        <button className='back-btn' onClick={() => navigate('/site/detail', { state: { siteId: siteDetailInfo.siteId } })}>戻る</button>
        <button className='save-btn' onClick={() => save()}>変更内容を保存</button>
      </div>
    </section>
  );
}