import { AxiosResponse } from 'axios';

export interface ApiResponse extends AxiosResponse {
  params: unknown,
  errors: [string],
  code: number
}

export module ApiRoutes {
  function createApiUrl(endpoint: string) {
    const root = `${process.env.REACT_APP_API_PREFIX}`;
    return `${root}${endpoint}`;
  }
  
  // client
  export const CLIENT_REGISTER = createApiUrl('/client/register'); // 広告主登録
  export const CLIENT_EDIT = createApiUrl('/client/edit'); // 広告主編集
  export const CLIENT_DETAIL = createApiUrl('/client/detail'); // 広告主詳細
  export const CLIENT_DELETE = createApiUrl('/client/delete'); // 広告主削除
  
  // partner
  export const PARTNER_REGISTER = createApiUrl('/v1_1/partner/register'); // パートナー登録
  export const PARTNER_EDIT = createApiUrl('/v1_1/partner/edit'); // パートナー編集
  export const PARTNER_DETAIL = createApiUrl('/v1_1/partner/detail'); // パートナー詳細
  export const PARTNER_LIST = createApiUrl('/partner/list'); // パートナー一覧
  export const PARTNER_DELETE = createApiUrl('/partner/delete'); // パートナー削除
  export const PARTNER_USERCHECK = createApiUrl('/v1_1/partner/usercheck'); // パートナー存在チェック
  
  // partner site
  export const PARTNER_SITE_REGISTER = createApiUrl('/v1_1/partner/site/register'); // パートナーサイト登録
  export const PARTNER_SITE_EDIT = createApiUrl('/v1_1/partner/site/edit'); // パートナーサイト編集
  export const PARTNER_SITE_DETAIL = createApiUrl('/v1_1/partner/site/detail'); // パートナーサイト詳細
  export const PARTNER_SITE_LIST = createApiUrl('/partner/site/list'); // パートナーサイト一覧
  export const PARTNER_SITE_DELETE = createApiUrl('/partner/site/delete'); // パートナーサイト削除
  
  // alliance
  export const ALLIANCE =  createApiUrl('/alliance'); // 提携処理
  export const ALLIANCE_REQUEST = createApiUrl('/v1_1/alliance/request') // 提携処理(パートナー用)
  
  // tag
  export const TAG_CLICK_LIST = createApiUrl('/tag/click/list'); // クリックURL一覧
  export const TAG_MEASURE_LIST = createApiUrl('/tag/measure/list'); // 計測タグ一覧
  export const TAG_GROUP_MEASURE_LIST = createApiUrl('/tag/group/measure/list'); // グループ計測タグ一覧
  export const TAG_RESULT_LIST = createApiUrl('/tag/result/list'); // 成果タグ一覧
  export const TAG_GROUP_RESULT_LIST = createApiUrl('/tag/group/result/list'); // グループ成果タグ一覧
  export const TAG_PRICE_DETAIL = createApiUrl('/tag/price/detail'); // 特別単価 詳細閲覧
  export const TAG_PRICE_EDIT = createApiUrl('/tag/price/edit'); // 特別単価 編集
  export const TAG_PRICE_DELETE = createApiUrl('/tag/price/delete'); // 特別単価 削除

  // content
  export const AD_REGISTER = createApiUrl('/ad/register'); // 広告登録
  export const AD_EDIT = createApiUrl('/ad/edit'); // 広告編集
  export const AD_DETAIL = createApiUrl('/ad/detail'); // 広告詳細
  export const AD_LIST = createApiUrl('/ad/list'); // 広告一覧
  export const AD_DELETE = createApiUrl('/ad/delete'); // 広告削除
  export const AD_ALLIANCELIST = createApiUrl('/v1_1/ad/alliancelist'); // 広告一覧(パートナー用)
  export const AD_DETAILPARTNER = createApiUrl('/v1_1/ad/detailpartner'); // 広告詳細(パートナー用)
  
  // content category
  export const AD_CATEGORY_REGISTER = createApiUrl('/ad/category/register'); // 広告カテゴリ登録
  export const AD_CATEGORY_EDIT = createApiUrl('/ad/category/edit'); // 広告カテゴリ編集
  export const AD_CATEGORY_LIST  = createApiUrl('/ad/category/list'); // 広告カテゴリ編集
  export const AD_CATEGORY_DELETE  = createApiUrl('/ad/category/delete'); // 広告カテゴリ削除
  
  // content group
  export const AD_GROUP_REGISTER = createApiUrl('/ad/group/register'); // 広告グループ登録
  export const AD_GROUP_EDIT = createApiUrl('/ad/group/edit'); // 広告グループ編集
  export const AD_GROUP_LIST = createApiUrl('/ad/group/list'); // 広告グループ一覧
  export const AD_GROUP_DELETE = createApiUrl('/ad/group/delete'); // 広告グループ削除
  
  // logs
  export const LOG_CLICK_LIST = createApiUrl('/log/click/list'); // クリックログ一覧
  export const LOG_CLICK_LISTSPAN = createApiUrl('/log/click/listspan'); // クリックログ一覧(期間対応)
  export const LOG_ACTION_LIST = createApiUrl('/log/action/list'); // 成果ログ一覧
  export const LOG_ACTION_LISTTIME = createApiUrl('/log/action/listtime'); // 成果ログ一覧 時刻対応
  export const LOG_ACTION_LISTPARTNER = createApiUrl('/v1_1/log/action/listpartner'); // 成果ログ一覧(パートナー用)
  export const LOG_ACTION_RESERVE_REGISTER = createApiUrl('/log/action/reserve/register'); // 成果ログ 一括更新
  export const LOG_ACTION_RESERVE_BASE64REGISTER = createApiUrl('/log/action/reserve/base64register'); // 成果ログ 一括更新(Base64エンコード版)
}