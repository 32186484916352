import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { UserState } from "store/types/UserActionTypes";
import { ApiResponse, ApiRoutes } from 'api/Api';
import { userLogin, userLogout } from "store/actions/userActions";
import { showTopPopup } from "store/actions/popupActions";
import { PartnerUsercheckReq, PartnerUsercheckRes, PartnerDetailReq, PartnerDetailRes } from "api/service/partners/PartnerTypes";
import axios from 'axios';

export default function useAuthService() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()

  async function login(mail: string, password: string): Promise<any> {
    try {
      const parm: PartnerUsercheckReq = {
        loginId: mail,
        password: password
      };
      const response: ApiResponse = await axios.post(ApiRoutes.PARTNER_USERCHECK, null, { params: parm });
      if (response && response?.params) {
        const checkUser: PartnerUsercheckRes = response.params as PartnerUsercheckRes;
        // パートナーが存在する場合ログイン処理開始
        if (checkUser.result) {
          const parm: PartnerDetailReq = {
            partnerId: checkUser.partnerId
          };
          const response: ApiResponse = await axios.post(ApiRoutes.PARTNER_DETAIL, null, { params: parm });
          if (response && response?.params) {
            const userDetail = response.params as PartnerDetailRes;
            const userInfo = { partnerId: userDetail.partnerId, userId: userDetail.loginId, userName: userDetail.name, mail: userDetail.mailAddress} as UserState;
            sessionStorage.setItem('currentUser', JSON.stringify(userInfo));
            dispatch(userLogin(userInfo));
            return userInfo;
          }
        }
      }
    } catch(error) {
      console.error(error);
    }
    return undefined;
  }

  async function logout(): Promise<any> {
    storageClear();
    return true;
  }

  function loginRequire(): boolean {
    const currentUser = sessionStorage.getItem('currentUser');
    if (currentUser) {
      return true;
    } else {
      dispatch(showTopPopup('ログインが必要です'));
      navigate('/login', { state: { prev: location.pathname } });
      return false;
    }
  }

  function getCurrentUser(): UserState | undefined {
    const currentUser = sessionStorage.getItem('currentUser');
    if (currentUser) {
      return JSON.parse(currentUser);
    } else {
      return undefined;
    }
  }

  function storageClear(): void {
    sessionStorage.removeItem('currentUser');
    dispatch(userLogout());
  }

  return {
    login,
    logout,
    loginRequire,
    getCurrentUser,
  };
}