import './MonthToggleButton.scss';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

/**
 * 月と日の表示ユニットを切り替えるためボタンコンポーネント
 *
 * @param {string} unit - 選択されている表示ユニット (`day` or `month`)
 * @param {React.Dispatch<React.SetStateAction<'day' | 'month'>} setUnit - 表示ユニットを設定するための関数
 */
export default function MonthToggleButton({ unit, setUnit } : { unit: string, setUnit: React.Dispatch<React.SetStateAction<'day' | 'month'>>}) {

  return(
    <div className='month-toggle-buttons'>
      <button className={unit === 'month' ? 'active' : ''} onClick={() => setUnit('month')}>
        {unit === 'month' && <CheckRoundedIcon className='icon'/>}月別
      </button>
      <button className={unit === 'day' ? 'active' : ''} onClick={() => setUnit('day')}>
        {unit === 'day' && <CheckRoundedIcon className='icon'/>}日別
      </button>
    </div>
  )
}