import './Backdrop.scss';

/**
 * バックドロップコンポーネント
 *
 * @param {boolean} open - バックドロップが表示されるかどうかを示すフラグ
 * @param {() => void} onClick - バックドロップをクリックしたときに呼び出されるコールバック関数（オプション）
 */
export default function Backdrop({ open, onClick }: { open: boolean, onClick?: () => void }) {

  return(
    <div className={open ? 'backdrop visible' : 'backdrop'} onClick={onClick}/>
  )
}