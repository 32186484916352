import { UserState } from 'store/types/UserActionTypes';
import AuthService from 'api/service/auth/AuthService';
import { ApiResponse, ApiRoutes } from 'api/Api';
import { PartnerRegisterReq, PartnerRegisterRes } from './PartnerTypes';
import { PartnerEditReq, PartnerEditRes } from './PartnerTypes';
import { PartnerDetailReq, PartnerDetailRes } from './PartnerTypes';
import { PartnerListReq, PartnerListRes } from './PartnerTypes';
import { PartnerDeleteReq } from './PartnerTypes';
import { PartnerUsercheckReq, PartnerUsercheckRes } from './PartnerTypes';
import axios from 'axios';

export default function PartnerService() {

  const authService = AuthService();

  const user: UserState = authService.getCurrentUser()!;

  // パートナー登録
  async function partnerRegister(parm: PartnerRegisterReq): Promise<PartnerRegisterRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.PARTNER_REGISTER, null, { params: parm });
      if (response && response?.params) {
        return response.params as PartnerRegisterRes;
      }
    } catch(error) {
      console.error(error);
    }
    return {} as PartnerRegisterRes;
  }

  // パートナー編集
  async function partnerEdit(parm: PartnerEditReq): Promise<PartnerEditRes> {
    try {
      parm = { ...parm, partnerId: user?.partnerId as number };
      const response: ApiResponse = await axios.post(ApiRoutes.PARTNER_EDIT, null, { params: parm });
      if (response && response?.params) {
        return response.params as PartnerEditRes;
      }
    } catch(error) {
      console.error(error);
    }
    return {} as PartnerEditRes;
  }

  // パートナー詳細
  async function partnerDetail(parm?: PartnerDetailReq): Promise<PartnerDetailRes> {
    try {
      parm = { ...parm, partnerId: user?.partnerId as number };
      const response: ApiResponse = await axios.post(ApiRoutes.PARTNER_DETAIL, null, { params: parm });
      if (response && response?.params) {
        return response.params as PartnerDetailRes;
      }
    } catch(error) {
      console.error(error);
    }
    return {} as PartnerDetailRes;
  }

  // パートナー一覧
  async function partnerList(parm?: PartnerListReq): Promise<PartnerListRes> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.PARTNER_LIST, null, { params: parm });
      if (response && response?.params) {
        return response.params as PartnerListRes;
      }
    } catch(error) {
      console.error(error);
    }
    return {} as PartnerListRes;
  }

  // パートナー削除
  async function partnerDelete(parm: PartnerDeleteReq): Promise<Array<string>> {
    try {
      const response: ApiResponse = await axios.post(ApiRoutes.PARTNER_DELETE, null, { params: parm });
      if (response && response?.params) {
        return response.params as Array<string>;
      }
    } catch(error) {
      console.error(error);
    }
    return {} as Array<string>;
  }

  // パートナー存在チェック
  async function partnerUsercheck(parm: PartnerUsercheckReq): Promise<PartnerUsercheckRes> {
    try {
      parm = { ...parm, loginId: user?.userId as string };
      const response: ApiResponse = await axios.post(ApiRoutes.PARTNER_USERCHECK, null, { params: parm });
      if (response && response?.params) {
        return response.params as PartnerUsercheckRes;
      }
    } catch(error) {
      console.error(error);
    }
    return {} as PartnerUsercheckRes;
  }
  
  return {
    partnerRegister,
    partnerEdit,
    partnerDetail,
    partnerList,
    partnerDelete,
    partnerUsercheck
  }
}