import { ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import AuthService from 'api/service/auth/AuthService';
import Backdrop from 'components/backdrop/Backdrop'
import './HeaderNav.scss';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import MenuItem from '@mui/material/MenuItem';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';

export interface MenuItem {
  category: string;
  categoryPath: string;
  categoryImg: string;
  items: {
    url: string,
    icon: ReactElement<any, any>,
    title: string
  }[];
}

/**
 * ヘッダーナビゲーションコンポーネント
 *
 * @param {string | undefined} userName - ユーザー名
 * @param {boolean} open - メニューが開いているかどうか
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setOpen - メニューの開閉状態を設定するための関数
 */
export default function HeaderNav({ userName, open, setOpen }: { userName: string | undefined, open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>}) {
  const isSp = useMediaQuery({ maxWidth: 767 });
  
  const authService = AuthService();

  async function logout() {
    await authService.logout().then(data => {
      window.location.reload();
    });
  }

  return(
    <>
      <Backdrop open={open} onClick={() => setOpen(false)}/>
        {userName &&
          <div className='username'>
            <label>パートナー名</label>
            {userName}
          </div>
        }
        {!isSp && userName &&
          <button className='logout-btn' onClick={() => logout()}>
            <span className='text'><PowerSettingsNewRoundedIcon className='icon'/></span>
          </button>
        }
        {isSp && 
          <button className='menu-btn' onClick={() => setOpen(!open)}>
            <MenuRoundedIcon className='icon'/>
          </button>
        }
    </>
  )
}