import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from "react-router-dom";
import MainTable, { Column } from 'components/table/MainTable'
import FilterModal, { ModalItem } from 'components/modal/FilterModal';
import { loading, unloading } from 'store/actions/loadingActions';
import { commaSeparate } from 'common/utils/StringUtils'
import ApexChart, { Chart, ChartOption } from 'components/chart/ApexChart';
import LogsService from 'api/service/logs/LogsService';
import AdService from 'api/service/ad/AdService';
import PartnerSiteService from 'api/service/partners/PartnerSiteService';
import { AdAllianceListReq } from 'api/service/ad/AdTypes';
import './Reports.scss';

interface ReportsTimeData {
  date: string;
  clickNum: number;
  acquisNum: number;
  denialNum: number;
  cvrSize: number;
  commission: number;
}

// TODO: 3月グラーフデータ、テーブルデータAPI取得可能予定
export default function ReportsTime() {
  const isSp = useMediaQuery({ maxWidth: 767 });
  const dispatch = useDispatch();
  const location = useLocation();

  const logsService = LogsService();
  const adService = AdService();
  const partnerSiteService = PartnerSiteService();

  const perPageList: Array<number> = [30, 60, 120];
  const [totalNum, setTotalNum] = useState<number>(12);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(perPageList[0]);

  const today: Date = new Date();
  const thisYear: number = today.getFullYear();

  const [yearList, setYearList] = useState<Array<number>>([]);
  const [modalItem, setModalItem] = useState<Array<ModalItem>>([
    { title: 'パートナーサイト', datas: { type: 'select', value: [], element: [] } },
    { title: '広告', datas: { type: 'select', value: [], element: [] } }
  ]);
  const siteItemIdx: number = 0;
  const adItemIdx: number = 1;
  const [selectedYear, setSelectedYear] = useState<number>();
  const [selectedMonth, setSelectedMonth] = useState<number>();
  const columns: Array<Column> = [
    { id: 'date', label: selectedMonth ? `${selectedMonth}月` : `${selectedYear}年`, minWidth: 170 },
    { id: 'clickNum', label: 'クリック', minWidth: 100, align: 'right', format: (value: number) => value.toLocaleString('en-US') },
    { id: 'acquisNum', label: '取得件数', minWidth: 170, align: 'right', format: (value: number) => value.toLocaleString('en-US') },
    { id: 'denialNum', label: '否認数', minWidth: 170, align: 'right', format: (value: number) => value.toLocaleString('en-US') },
    { id: 'cvrSize', label: 'CVR', minWidth: 100, align: 'right', format: (value: number) => value + '%' },
    { id: 'commission', label: '報酬額', minWidth: 170, align: 'right', format: (value: number) => '¥' + value.toLocaleString('en-US') }
  ];
  const [rows, setRows] = useState<Array<ReportsTimeData>>([]);
  
  useEffect(() => {
    const lastYears = [thisYear, thisYear - 1, thisYear - 2];
    setYearList(lastYears);
    setSelectedYear(lastYears[0]);
    getMeodalInfo();
    getTableInfo()
  }, []);

  useEffect(() => {
    const year: number = location.state?.year;
    const month: number = location.state?.month;
    if (year) {
      setSelectedYear(year);
    }
    if (month) {
      setSelectedMonth(month);
    }
  }, [location]);
  
  function createData(date: string, clickNum: number, acquisNum: number, denialNum: number, cvrSize: number, commission: number): ReportsTimeData {
    return { date, clickNum, acquisNum, denialNum, cvrSize, commission };
  }

  async function getMeodalInfo() {
    load();
    const filterSiteList: Array<{label: string, value: number}> = [];
    const filterAdList: Array<{label: string, value: number}> = [];
  
    await partnerSiteService.partnerSiteList().then((res) => {
      res.sites?.forEach((site) => {
        filterSiteList.push({ label: site.siteName, value: Number(site.siteId) })
        const parm: AdAllianceListReq = {
          partnerSiteId: site.siteId,
          row: 120
        }
        adService.adAllianceList(parm).then((res) => {
          res.contents?.forEach((content) => {
            const contentIdx = filterAdList.findIndex(item => item.value === Number(content.contentId));
            if (contentIdx === -1) {
              filterAdList.push({ label: content.contentName, value: Number(content.contentId) });
            }
          })
        })
      })
    })
  
    setModalItem((prev) => {
      const modalItems = [...prev];
      const siteItem = modalItems[siteItemIdx];
      const adItem = modalItems[adItemIdx];
      if (siteItem.datas.type === 'select') {
        modalItems[siteItemIdx] = { ...siteItem,
          datas: { ...siteItem.datas,
            element: filterSiteList
          }
        };
      }
      if (adItem.datas.type === 'select') {
        modalItems[adItemIdx] = { ...adItem,
          datas: { ...adItem.datas,
            element: filterAdList
          }
        };
      }
      return modalItems;
    });
    unload();
  }

  async function getTableInfo() {
    load();
    setRows([
      createData('2023年 01月', 1000, 1324354, 3263, 10, 1324354),
      createData('2023年 02月', 1000, 1403365, 9961, 10, 1403365),
      createData('2023年 03月', 1000, 60473, 301, 10, 60473),
      createData('2023年 04月', 1000, 327434, 9830, 10, 327434),
      createData('2023年 05月', 1000, 37603, 9980, 10, 37603),
      createData('2023年 06月', 1000, 25400, 7624, 10, 25400),
      createData('2023年 07月', 1000, 83200, 378, 10, 83200),
      createData('2023年 08月', 1000, 4000, 3, 10, 4000),
      createData('2023年 09月', 1000, 127691, 550, 10, 0),
      createData('2023年 10月', 1000, 17000, 973, 10, 170),
      createData('2023年 11月', 1000, 67000, 679, 10, 67000),
      createData('2023年 12月', 1000, 67757, 295,10, 67757),
    ]);
    unload();
  }

  function load() {
    dispatch(loading(true));
  }
  
  function unload() {
    dispatch(unloading());
  }

  const dummyChartData: Chart = {
    height: isSp ? 280 : 420,
    options: {
      chart: ChartOption,
      colors: ['var(--graph1-color)', 'var(--graph3-color)'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight',
        width: [0, 3]
      },
      markers: {
        size: 0,
        colors: 'white',
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          gradientToColors: ['var(--graph2-color)', 'var(--graph4-color)'],
          shadeIntensity: 1,
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        },
      },
      yaxis: [{
        labels: {
          style: {
            colors: 'lightgray'
          }
        }  
      }, {
        opposite: true,
        labels: {
          style: {
            colors: 'lightgray'
          }
        } 
      }],
      xaxis: {
        labels: {
          style: {
            colors: 'lightgray'
          }
        },
        type: 'category',
        categories: [
          '1月', '2月', '3月', '4月', '5月', '6月',
          '7月', '8月', '9月', '10月', '11月', '12月'
        ],
        tickAmount: isSp ? 6 : 'dataPoints'
      },
      tooltip: {
        theme: 'dark',
        shared: true,
        y: [
          {
            formatter: function (val: number) {
              return commaSeparate(val) + '件';
            }
          },
          {
            formatter: function (val: number) {
              return val + '%';
            }
          }
        ]
      },
      grid: {
        borderColor: 'var(--shadow-color)'
      },
      legend: {
        show: true,
        labels: {
          colors: 'lightgray'
        }
      }
    },
    series: [{
      name: '獲得数',
      type: 'column',
      data: [
        15000, 18000, 26000, 13000,
        15000, 19000, 20000, 25000,
        20000, 19000, 22000, 25000
      ]}
      ,{
      name: '獲得CVR',
      type: 'line',
      data: [
        20, 38, 26, 13,
        25, 19, 20, 25,
        30, 19, 22, 25
      ]}
    ],
  };

  return(
    <section className='reports'>
      <div className='top'>
        <FilterModal items={modalItem} setItems={setModalItem}/>
      </div>
      <div className='middle'>
        <ApexChart title='時系列レポート' unit={['件','%']} chartData={dummyChartData} reportFlg={true} additional={
          <div className='buttons'>
            {yearList.map((year) => (
              <button key={year} className={`year ${selectedYear === year ? 'active' : ''}`} onClick={() => setSelectedYear(year)}>{year + '年'}</button>
            ))}
          </div>
        }/>
      </div>
      <div className='bottom'>
        <MainTable totalNum={totalNum} columns={columns} rows={rows}/>
      </div>
    </section>
  )
}