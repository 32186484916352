import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import MainTable, { Column } from 'components/table/MainTable';
import PartnerSiteService from 'api/service/partners/PartnerSiteService';
import { PartnerSiteListReq } from 'api/service/partners/PartnerSiteTypes';
import { loading, unloading } from 'store/actions/loadingActions';
import './Site.scss';

interface SiteInfoData {
  siteId: number;
  name: string;
  date: string;
}

export default function Site() {
  const isSp = useMediaQuery({ maxWidth: 767 });
  const dispatch = useDispatch();

  const partnerSiteService = PartnerSiteService();

  const perPageList: Array<number> = [30, 60, 120];
  const [totalNum, setTotalNum] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(perPageList[0]);

  const columns: Array<Column> = [
    { id: 'name', label: 'パートナーサイト名', minWidth: 200 },
    { id: 'date', label: '登録日', width: 130, hide: isSp, align: 'right' },
  ];
  const [rows, setRows] = useState<Array<SiteInfoData>>([]);

  useEffect(() => {
    getTableInfo();
  }, [page, perPage]);

  function load() {
    dispatch(loading(true));
  }
  
  function unload() {
    dispatch(unloading());
  }

  function createData(siteId: number, name: string, date: string): SiteInfoData {
    return { siteId, name, date };
  }

  async function getTableInfo() {
    load();
    const parm: PartnerSiteListReq = {
      page: page,
      row: perPage
    };
    const tableDataList: Array<SiteInfoData> = [];

    await partnerSiteService.partnerSiteList(parm).then((res) => {
      setTotalNum(res.totalNum);
      res.sites?.forEach((site) => {
        tableDataList.push(createData(site.siteId, site.siteName, site.insertAt));
      })
    });
    setRows(tableDataList);
    unload();
  }

  return(
    <section className='site'>
      {/* テーブル目次 */}
      <div className='table-box'>
        <MainTable totalNum={totalNum} menuFlg={true} columns={columns} rows={rows} 
          page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} perPageList={perPageList}/>
      </div> 
    </section>
  );
}